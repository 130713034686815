import React, { Component,Fragment } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter, Link } from 'react-router-dom';
import { toast , isApiOk , showApiError, intval } from '../util/Function';
import StdIcon from '../util/Icon';
import { Button, Colors, EditableText, InputGroup, Overlay } from "@blueprintjs/core";
import IndexBoxChapterItem from '../component/CicIndexBoxChapterItem'; 
import IndexBoxSectionItem from '../component/CicIndexBoxSectionItem'; 

@withRouter
@inject("store")
@observer
export default class IndexBoxReadOnly extends Component
{
    state = {"chapter_name":"","section_names":{},"index":null};

    componentDidMount()
    {
        this.lesson_id = this.props.lesson_id;
    }

    
    render()
    {
        let data = this.props.data ;
        const section_id = this.props.section_id ? this.props.section_id : 0 ;
        if( !data ) data = {};
        
        return <div className="std-index-readonly"><ul className="chapter-index">
        { Object.values(data).sort( (a , b ) => a.order - b.order ).map( (item) => <Fragment key={item.id}>
            <li className="indexbox-item" >
            <StdIcon name="book" size={22} color={Colors.GRAY3} className="mr5"/> {item.title}        
            </li>
            {item.sections && Object.values(item.sections).sort( (a , b ) => a.order - b.order ).map( (section) => 
            {
                const classname = section_id == section.id ? 'section active' : 'section';

                const section_link = this.props.preview ? "/student/lesson/section/"+section.id + "/preview" : "/student/lesson/section/"+section.id;

                return <li className={classname} key={section.id}>
                <Link to={section_link}>{section.title}</Link>
                </li> ;
                
            }  
            
            

            
            
            
            ) }
            
            </Fragment>
        )}

        </ul></div>;
    }
}