import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter,Link } from 'react-router-dom';
import CicAdminLayout from '../component/CicAdminLayout';
import { Tab, Tabs, Icon } from "@blueprintjs/core";
import { isApiOk , showApiError, setStringGlobal, handleStringGlobal,handleIsGlobal, toast, intval } from '../util/Function';
import CicCodeTable from '../component/CicCodeTable'; 


@withRouter
@inject("store")
@observer
export default class CicCodeManage extends Component
{
    state = {"code_list":[],"lesson":{},"selected_tab":"lived"};
    
    async componentDidMount()
    {
        const id = intval(this.props.match.params.id);
        if( id > 0 ) 
        {
            const { data } = await this.props.store.lesson_detail( id );
            if( isApiOk( data ) )
            {
                this.setState( {"lesson":data.data,"id":id} )
                await this.loadCode( id );
            }
            else
                showApiError( data ); 
        }
        else
        {
            toast( "课程ID错误" );
            this.props.history.goBack();
        }
    }

    async loadCode( id )
    {
        const { data } = await this.props.store.code_list( id );
        //const data = null;
        if( isApiOk( data ) )
        {
            // console.log( data.data );
            this.setState( {"code_list":data.data} );
        }
        else
            showApiError( data );
    }

    selected( e )
    {
        this.setState({"selected_tab":e});
    }
    
    updated()
    {
        this.loadCode( this.state.id );
    }
    
    render()
    {
        const lid = this.state.id;
        
        const main = <div className="padbox">
        <Tabs id="lesson-table"  selectedTabId={this.state.selected_tab} onChange={(e)=>this.selected(e)}>
        <Tab id="lived" title="可用领课码" panel={<CicCodeTable data={this.state.code_list && this.state.code_list.filter( item => parseInt(item.disabled) !== 1 && parseInt(item.sent) !== 1 && parseInt(item.used_times) <= parseInt(item.can_use_times) )} onUpdated={()=>this.updated()} lesson_id={lid} />} />
        
        <Tab id="sent" title="已发送" panel={<CicCodeTable data={this.state.code_list && this.state.code_list.filter( item => parseInt(item.sent) === 1 )} onUpdated={()=>this.updated()} lesson_id={lid} />} />


        <Tab id="used" title="已领取" panel={<CicCodeTable data={this.state.code_list && this.state.code_list.filter( item => parseInt(item.used_times) === parseInt(item.can_use_times)  )} onUpdated={()=>this.updated()} lesson_id={lid} />} />

        <Tab id="disabled" title="已失效" panel={<CicCodeTable data={this.state.code_list && this.state.code_list.filter( item => parseInt(item.disabled) === 1 )} onUpdated={()=>this.updated()} lesson_id={lid} />} />
        

        </Tabs> </div>;
        
    const side = <div className="padbox cic-sidemenu"><Icon icon="plus" className="cic-icon" /><Link to={"/admin/code/create/"+this.state.lesson.id}>添加此课程的领课码</Link></div>;

        const title = <div className="cic-bread-nav"><Link to="/admin/lesson">课程管理</Link >  <Icon icon="double-chevron-right" className="cic-icon" /> {this.state.lesson.title} <Icon icon="double-chevron-right" className="cic-icon" /> 领课码管理 </div>;

        return <CicAdminLayout main={main} side={side} title={title} />;
    }
}