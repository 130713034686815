import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter, Link } from 'react-router-dom';
import CicAdminLayout from '../component/CicAdminLayout'; 
import { toast , isApiOk , showApiError } from '../util/Function';
import { Tab, Tabs, Icon } from "@blueprintjs/core";
import CicLessonTable from '../component/CicLessonTable'; 


@withRouter
@inject("store")
@observer
export default class CicLessonManage extends Component
{
    state = {"lesson_list":[],"selected_tab":"lived"};
    
    async componentDidMount()
    {
        this.loadLesson();
    }

    async loadLesson()
    {
        const { data } = await this.props.store.lesson_list();
        //const data = null;
        if( isApiOk( data ) )
        {
            // console.log( data.data );
            this.setState( {"lesson_list":data.data} );
        }
        else
            showApiError( data );
    }

    selected( e )
    {
        this.setState({"selected_tab":e});
    }  
    
    render()
    {
        const main = <div className="padbox">
            <Tabs id="lesson-table"  selectedTabId={this.state.selected_tab} onChange={(e)=>this.selected(e)}>
            <Tab id="lived" title="可用课程" panel={<CicLessonTable data={this.state.lesson_list && this.state.lesson_list.filter( item => parseInt(item.deleted) !== 1 )} onDelete={()=>this.loadLesson()} onUndelete={()=>this.loadLesson()} />} />
            <Tab id="deleted" title="已归档" panel={<CicLessonTable data={this.state.lesson_list && this.state.lesson_list.filter( item => parseInt(item.deleted) === 1 )} onDelete={()=>this.loadLesson()} onUndelete={()=>this.loadLesson()} />} />
            {/* <Tabs.Expander />
                <input className="bp3-input" type="text" placeholder="Search..." />*/}
            </Tabs> 
        </div>;



        const side = <div className="padbox cic-sidemenu"><Icon icon="plus" className="cic-icon" /><Link to="/admin/lesson/create">创建新课程</Link></div>;
        const title = <span>课程管理</span>;
        return <CicAdminLayout main={main} side={side} title={title} />;
    }
}