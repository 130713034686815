import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button, Menu, MenuItem, MenuDivider, Popover, Position } from "@blueprintjs/core";

@withRouter
@inject("store")
@observer
export default class UserMenu extends Component
{
    render()
    {
        const exampleMenu = (
            <Menu>
                
                {this.props.type == "admin" ? <MenuItem icon="people" text="学员界面" href="/student/lesson" target="_blank" /> : this.props.store.level > 1 && <MenuItem icon="control" text="讲师工作台" href="/admin/lesson" target="_blank" /> }
                
                <MenuItem icon="box" text="云沙箱管理" href="/vps" target="_blank" />
                <MenuItem icon="time" text="购买云沙箱时间">
                    <MenuItem icon="time" text="600分钟/6元" href="https://learn.ftqq.com/17" target="_blank"/>
                    <MenuItem icon="time" text="6000分钟/60元" href="https://learn.ftqq.com/22" target="_blank"/>
                    <MenuItem icon="time" text="12000分钟/120元" href="https://learn.ftqq.com/21" target="_blank"/>
                
                </MenuItem>
                <MenuDivider />
                <MenuItem icon="log-out" text="退出" onClick={()=>this.props.history.push("/logout")} />
            </Menu>
        );
        return  <div>{ this.props.store.nickname && <Popover content={exampleMenu} position={Position.BOTTOM}>
        <Button rightIcon="caret-down" minimal={true} />
        </Popover>}</div>;
    }
}