import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import StdIcon from '../util/Icon';
import { Icon, Colors, EditableText, InputGroup } from "@blueprintjs/core";


@withRouter
@inject("store")
@observer
export default class IndexBoxChapterItem extends Component
{
    state = {"title":this.props.data};
     
    onConfirm( value )
    {
        if( this.props.onConfirm ) this.props.onConfirm( value );
    }

    render()
    {
        
        return <div className="chapter"><StdIcon name="book" size={22} color={Colors.GRAY3} className="mr5"/><EditableText value={this.state.title} onChange={value=>this.setState({"title":value})} onConfirm={value=>this.onConfirm(value)}/></div>;
    }
}