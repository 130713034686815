import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import CicJsonPlayerPage from '../component/CicJsonPlayerPage'; 
import { toast, intval } from '../util/Function';

@withRouter
@inject("store")
@observer
export default class CicJsonPlayer extends Component
{
    state = {"pages":[],"max":0,"now":0};
    
    componentDidMount()
    {
        this.loadData();
    }
    
    componentDidUpdate(prevProps)
    {
        if (this.props.data !== prevProps.data) 
        {
            this.loadData();
        }
    }

    loadData()
    {
        const json_data = this.props.data ? JSON.parse( this.props.data ) : null;

        if( json_data )
        {
            const now_data = this.state.now >= json_data.length ? json_data.length - 1 : this.state.now;
            
            this.setState( {"pages":json_data,"max":json_data.length,"now":now_data} )
        }
    }

    next()
    {
        const next = this.state.now+1;
        if( next < this.state.max  )
        {
            this.setState( {"now":next} );
        }
        else
        {
            toast("已经是最后一页了");
        }
    }

    pre()
    {
        const pre = this.state.now-1;
        if( pre >= 0  )
        {
            this.setState( {"now":pre} );
        }
        else
        {
            toast("已经是最前页了");
        }
    }

    render()
    {
        if( !this.props.data ) return null;
        // console.log( "json_data" , json_data );

        const page_info = this.state.pages[this.state.now];
        if( !page_info ) return null;
        // console.log( page_info  );
        return <CicJsonPlayerPage width={this.props.width}
            data={page_info} 
            onNext={()=>this.next()} 
            onPre={()=>this.pre()}
        />;
    }
}