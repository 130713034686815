import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter,Link } from 'react-router-dom';
import CicAdminLayout from '../component/CicAdminLayout';
import { Tab, Tabs, Icon, AnchorButton, FormGroup, InputGroup, Switch, RadioGroup, Radio, Checkbox, TextArea, Button, Intent, ControlGroup, HTMLSelect } from "@blueprintjs/core";
import { isApiOk , showApiError, setStringGlobal, handleStringGlobal,handleIsGlobal, toast, intval } from '../util/Function';

@withRouter
@inject("store")
@observer
export default class CicSectionSettings extends Component
{
    state = {"section":{},"sandbox":[]};
    
    async componentDidMount()
    {
        const id = intval(this.props.match.params.id);
        this.section_id = id;
        if( id > 0 ) 
        {
            const { data } = await this.props.store.section_detail( id );
            if( isApiOk( data ) )
            {
                const section = data.data
                
                this.setState( {"section":section} );
                this.props.store.section_sandbox_id = intval( section.sandbox_id );

                await this.loadSandbox();
            }
            else
                showApiError( data ); 
        }
        else
        {
            toast( "课程ID错误" );
            this.props.history.goBack();
        }
    }

    async loadSandbox()
    {
        const { data } = await this.props.store.sandbox_list();
        if( isApiOk( data ) )
        {
            this.setState( {"sandbox":data.data} );
        }
        else
            showApiError( data ); 
    }

    async submit()
    {
        const { store } = this.props;
        
        const { data } = await store.section_settings_update( this.section_id );
        if( isApiOk( data ) )
        {
            if( data.data && data.data.id )
            {
                // 清理临时课程数据
                store.section_settings_init(); 
                this.props.history.replace("/admin/lesson/section/"+this.section_id+"/edit");
            }
        }
        else showApiError( data );

    }
    
    render()
    {
        const { store } = this.props;
        
        const main = <div className="padbox">
            
        <FormGroup
            label="选择本节课程使用的沙箱"
        >
            <HTMLSelect value={store.section_sandbox_id} onChange={(e)=>handleStringGlobal(e,'section_sandbox_id')} large={true}>
                <option value="0">不使用沙箱</option>
                { this.state.sandbox && this.state.sandbox.map( item => <option key={item.id} value={item.id}>{item.title}</option> ) }
            </HTMLSelect>
        </FormGroup> 

        <div className="std-hr form-div"/>

        <FormGroup>
        <div className="row-half">
            <div className="left">
            <Button large="true" onClick={()=>this.props.history.goBack()} >取消</Button>
            </div>
            <div className="right">
            <Button large="true" intent={Intent.PRIMARY} onClick={()=>this.submit()}>提交</Button>
            </div>
        </div>
        
        </FormGroup>
        </div>;
        
        const side = null;

        const title = this.state.section.lesson ? <div className="cic-bread-nav font-16"><Link to="/admin/lesson" className="left-10">课程管理</Link > <Icon icon="double-chevron-right" className="cic-icon" /> <Link to={"/admin/lesson/index/"+this.state.section.lesson.id}>{this.state.section.lesson.title}</Link> <Icon icon="double-chevron-right" className="cic-icon" /> {this.state.section.chapter.title} <Icon icon="double-chevron-right" className="cic-icon" /> {this.state.section.title} <Icon icon="double-chevron-right" className="cic-icon" /> 设置 </div>: null;

        return <CicAdminLayout main={main} side={side} title={title} />;
    }
}