import React, { Component,Fragment } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import CicWideHeader from '../component/CicWideHeader'; 

import './cic.admin.scss';

@withRouter
@inject("store")
@observer
export default class CicWideOneLayout extends Component
{
    render()
    {
        const main = this.props.main ? this.props.main : null; 
        const side = this.props.side ? this.props.side : null; 
        const title = this.props.title ? this.props.title : null; 

        return <DocumentTitle title={this.props.store.appname}>
            <Fragment>
            <CicWideHeader title={title} type={this.props.type} />
            <div className="cic-one-box">{main}</div>
            </Fragment>
            </DocumentTitle>;
    }
}