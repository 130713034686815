import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter,Link } from 'react-router-dom';
import CicAdminLayout from '../component/CicAdminLayout';
import { Icon } from "@blueprintjs/core"; 
import { isApiOk , showApiError, setStringGlobal, handleStringGlobal,handleIsGlobal, toast, intval } from '../util/Function';
import CicIndexBox from '../component/CicIndexBox'; 

@withRouter
@inject("store")
@observer
export default class CicIndexManage extends Component
{
    state = {"index":[],"lesson":{},"selected_tab":"lived"};
    
    async componentDidMount()
    {
        const id = intval(this.props.match.params.id);
        if( id > 0 ) 
        {
            const { data } = await this.props.store.lesson_detail( id );
            if( isApiOk( data ) )
            {
                this.setState( {"lesson":data.data,"id":id} )
                await this.loadIndex( id );
            }
            else
                showApiError( data ); 
        }
        else
        {
            toast( "课程ID错误" );
            this.props.history.goBack();
        }
    }

    async loadIndex( id )
    {
        const { data } = await this.props.store.index_load( id );
        if( isApiOk( data ) )
        {
            this.setState( {"index":data.data} );
        }
        else
            showApiError( data );
    }
    
    render()
    {
        const main = <div className="padbox center-v std-index"><CicIndexBox 
        data={this.state.index} 
        lesson_id={this.state.id} onIndexUpdated={()=>this.loadIndex(this.state.id)}
        /></div>;
        
        const side = null;

        const title = <div className="cic-bread-nav"><Link to="/admin/lesson">课程管理</Link >  <Icon icon="double-chevron-right" className="cic-icon" /> {this.state.lesson.title} <Icon icon="double-chevron-right" className="cic-icon" /> 章节管理 </div>;

        return <CicAdminLayout main={main} side={side} title={title} />;
    }
}