import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import CicSiteHeader from '../component/CicSiteHeader'; 



import './cic.site.scss';

@withRouter
@inject("store")
@observer
export default class CicSiteLayout extends Component
{
    render()
    {
        const title = this.props.title ? this.props.title : null; 

        return <DocumentTitle title={this.props.store.appname}><div className="cic-site-box bg-drak">

        <CicSiteHeader title={this.props.title} no_menu={this.props.no_menu} />
        <div className="cic-site-content bg-drak">
        {this.props.children} 
           
        </div>   
        

        </div></DocumentTitle>;
    }
}