import { observable, action, computed } from "mobx";
import axios from 'axios';
import { sprintf } from 'sprintf-js';
import { rclean, intval, nextorder,  nextItem, ftsort, isApiOk, showApiError, toast } from '../util/Function';
import Sound from 'react-sound';
import moment from 'moment';
import 'moment/locale/zh-cn.js';
moment.locale('zh-cn');

const API_BASE = process.env.REACT_APP_API_BASE ;

class CicState
{
    constructor()
    {
        this.lesson_data_init();
        this.loadUser();
    }
    
    @observable appname = "课猫云";  
    @observable token = null;

    // user info
    @observable uid = 0;
    @observable level = 0;
    @observable nickname = "";
    @observable avatar = "";

    // lesson to add
    @observable lesson_title = "";
    @observable lesson_subtitle = "";
    @observable lesson_cover_url = "";
    @observable lesson_container_domain = "";
    @observable lesson_price = 99.9;

    @observable lesson_expire_check_type = 1; // 1=不自动检查 2=购买后N天
    @observable lesson_expire_after_buy = 90; // 购买后90天
    @observable lesson_sandbox_minute = 0; // 不赠送

    // 正版引导
    @observable lesson_promo_text = ''; 
    @observable lesson_promo_fullprice_link = ''; 
    @observable lesson_promo_discount_countdown = 0; 
    @observable lesson_promo_discount_link = ''; 
    
    // 课猫学院相关设置
    @observable lesson_platform_ci2 = 0; 
    @observable lesson_ci2_cates = []; 
    @observable lesson_ci2_cate = ""; 
    @observable lesson_ci2_color = ""; 
    @observable lesson_ci2_image_url = ""; 

    @observable lesson_desp = "";
    @observable lesson_published = 0;

    // 沙箱
    @observable sandbox_type = 2; // 1= 浏览器沙箱 2 = 云沙箱
    @observable sandbox_title = "";
    @observable sandbox_image = "";
    @observable sandbox_image_type = 1; // 1= 自定义 2= ubuntu 3= vscode
    @observable sandbox_entry_page = ""; // 1= 自定义 2= ubuntu 3= vscode
    @observable sandbox_entry_port = 8080; // 1= 自定义 2= ubuntu 3= vscode
    @observable sandbox_image_type = 1; // 1= 自定义 2= ubuntu 3= vscode
    @observable sandbox_level = 1; // 1 = 256m 2cpu ; 2= 512m 4cpu ; 3=  1g 8cpu; 4= 2g 4cpu 
    @observable sandbox_published = 0; // 1 = 256m 2cpu ; 2= 512m 4cpu ; 3=  1g 8cpu; 4= 2g 4cpu 

    // 领课码
    @observable code_lesson_id = 0;
    @observable code_count = 1;
    @observable code_can_use_times = 1;
    @observable code_sanbox_minutes = 0;
    @observable code_expire_date = moment().add(30, 'days').format("YYYY-MM-DD");
    @observable code_notes = "";

    // 系统设置
    @observable settings_resouce_domain = "";
    @observable settings_resouce_path = "";
    @observable settings_slidebox_key = "";

    // 课程内容编辑
    @observable section_markdown = "";
    @observable section_json = "";
    @observable section_sandbox_id = "";
    @observable section_slidebox_key = "";

    // 云沙箱
    @observable vps_password = "";

    // 播放器
    @observable player_action_list = [];
    @observable player_action_current_number = 0;

    @observable player_playing = false;
    @observable player_status = Sound.status.PAUSED;

    @computed get player_action_current()
    {
        //console.log( JSON.stringify(this.player_action_list) );
        //console.log( JSON.stringify(this.player_action_current_number) );
        
        return this.player_action_list[this.player_action_current_number];
        
        if( this.player_action_list && this.player_action_current_number )
            return this.player_action_list[this.player_action_current_number];
        else
            return false;    
    }

    @computed get player_action_current_url()
    {
        return this.player_action_current && this.player_action_current.audio_url;
        
        if( this.player_action_current && this.player_action_current.audio_url)
            return this.player_action_current.audio_url;
        else
            return false;    
    }

    @action set_player_action_data( list )
    {
        this.player_action_current_number = 0;
        this.player_action_list = list;
    }

    // // 折扣
    // @observable lesson_has_discount = 0;
    // @observable lesson_discount_price = 99.9;
    // @observable lesson_discount_start_date = moment().format("YYYY-MM-DD");
    // @observable lesson_discount_end_date = moment().add(3, 'days').format("YYYY-MM-DD");
    
    
    // @observable lesson_expire_date = moment().add(365, 'days').format("YYYY-MM-DD"); // 默认九十天后
    
    
    // @observable lesson_is_updating = 0;
    // @observable lesson_updating_desp = "";

    
    // @observable review_desp = "";

    // @observable lesson_scripts = {};
    // @observable lesson_scripts_current_order = 0;
    // @observable lesson_scripts_last_order = 0;
    // @observable lesson_scripts_maxorder = 1;

    // @observable lesson_slide_url = "";

    // @observable lesson_video_on = false;
    // @observable lesson_video_url = "";

    // @observable lesson_subtitle_text = "";
    // @observable lesson_js_text = "";

    // @observable lesson_post_url = "";
    // @observable lesson_post_data = "";
    // @observable lesson_post_reg = "";
    // @observable lesson_post_error_notice = "";
    
    
    // @observable lesson_git_url = "";
    // @observable lesson_git_tag = "";
    
    // @observable lesson_form_action = "insert";
    // @observable lesson_form_action_id = 0;

    // @observable lesson_focus = "code";
    // @observable lesson_focus_tab = "action_slide";

    // @observable lesson_role_url = "";
    // @observable lesson_roles = [];
    

    // @observable lesson_preload_url = null;

    // @observable terminal_on = false;
    // @observable terminal_url = 'http://localhost:3456';
    
    // @observable terminal_url2 = 'http://localhost:3456';
    // @observable terminal2_on = false;
    
    // @observable playground_on = false;
    // @observable playground_url = 'http://8.playcode.sinaapp.com/';

    // @observable dynalist_on = false;
    // @observable dynalist_url = 'https://dynalist.io/';

    // @observable drawio_on = false;
    // @observable drawio_url = 'https://www.draw.io/';

    // @observable browser_on = false;
    // @observable browser_url = '';
    // @observable browser_current_url = '';

    // @observable script_plane_on = true;
    // @observable info_plane_on = true;
    // @observable action_plane_on = true;
    @action
    section_set_markdown( $section )
    {
        this.section_markdown = $section.cic_markdown;
        this.section_json = $section.cic_json;
    }

    async settings_load()
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        return await axios.post( API_BASE + 'cic/settings/list' , params );
    }

    async settings_update()
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("resouce_domain" , this.settings_resouce_domain);
        params.append("resouce_path" , this.settings_resouce_path);
        params.append("slidebox_key" , this.settings_slidebox_key);

        return await axios.post( API_BASE + 'cic/settings/update' , params );
    }

    

    @action settings_set( data )
    {
        this.settings_resouce_domain = data.resouce_domain;
        this.settings_resouce_path = data.resouce_path;
        this.settings_slidebox_key = data.slidebox_key;
    }

    async section_update_markdown( id , markdown )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        params.append("markdown" , markdown);

        return await axios.post( API_BASE + 'cic/section/markdown/update' , params );
    }


    async section_build_voice( id )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);

        return await axios.post( API_BASE + 'cic/section/voice/build' , params );
    }

    async user_log( id )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);

        return await axios.post( API_BASE + 'cic/user/log' , params );
    }

    async user_logset(  text , id )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        params.append("text" , text);

        return await axios.post( API_BASE + 'cic/user/logset' , params );
    }

    
    @action
    setUser( user )
    {
        this.uid = user.uid;
        this.level = user.level;
        this.nickname = user.nickname;
        this.avatar = user.avatar;
        this.token = user.token;
        
        window.localStorage.setItem( "SJUSER" , JSON.stringify( user ) );
    }

    @action
    loadUser()
    {
        let data = false;
        const data_json = window.localStorage.getItem( "SJUSER" );
        if( data_json ) data = JSON.parse( data_json );
        if( data ) this.setUser( data );
    }

    @action
    cleanUser()
    {
        window.localStorage.removeItem("SJUSER");
        this.uid = 0;
        this.level = 0;
        this.nickname = "";
        this.avatar = "";
        this.token = "";
    }
    
    async loadQRCode( lesson_id  )
    {
        var params = new URLSearchParams();
        params.append("type" , "cic");
        if( lesson_id ) params.append("lesson_id" , lesson_id);
        return await axios.post( API_BASE + 'user/signin' , params );
    }

    async loadQRCode2( lesson_id  )
    {
        var params = new URLSearchParams();
        params.append("type" , "cic");
        if( lesson_id ) params.append("lesson_id" , lesson_id);
        return await axios.post( API_BASE + 'user/codein' , params );
    }

    async checkQRCode()
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        
        return await axios.post( API_BASE + 'sso/check' , params );
    }

    async sa_login( uid )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("uid" , uid);
        
        return await axios.post( API_BASE + 'sa/login' , params );
    }

    async connect( code  )
    {
        var params = new URLSearchParams();
        params.append("code" , code);
        return await axios.post( API_BASE + 'user/connect' , params );
    }

    async uploadImage( blob )
    {
        let formData = new FormData();
        formData.append("image", blob );
        formData.append("cic", 1 );
        formData.append("token", this.token);
        return await axios.post( API_BASE + 'image/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        })
    }

    async index_load( id )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);

        return await axios.post( API_BASE + 'cic/index/load' , params );
    }

    async myindex_load( id )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);

        return await axios.post( API_BASE + 'cic/myindex/load' , params );
    }

    async mylesson_list()
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        return await axios.post( API_BASE + 'cic/mylesson/list' , params );
    }

    async chapter_save( id , title )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        params.append("title" , title);

        return await axios.post( API_BASE + 'cic/chapter/save' , params );
    }

    async chapter_update( id , title )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        params.append("title" , title);

        return await axios.post( API_BASE + 'cic/chapter/update' , params );
    }

    async chapter_remove( id )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);

        return await axios.post( API_BASE + 'cic/chapter/remove' , params );
    }

    async chapter_list( id )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        return await axios.post( API_BASE + 'cic/chapter/list' , params );
    }

    async lesson_order_update( id , new_order )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        params.append("order_json" , JSON.stringify( new_order ));

        return await axios.post( API_BASE + 'cic/lesson/order/update' , params );
    }

    async section_save( id , chapter_id , title )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        params.append("chapter_id" , chapter_id);
        params.append("title" , title);

        return await axios.post( API_BASE + 'cic/section/save' , params );
    }

    async section_update( id , title )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        params.append("title" , title);

        return await axios.post( API_BASE + 'cic/section/update' , params );
    }

    async section_remove( id )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);

        return await axios.post( API_BASE + 'cic/section/remove' , params );
    }

    async section_list( id )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        return await axios.post( API_BASE + 'cic/section/list' , params );
    }

    async section_detail( id )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        return await axios.post( API_BASE + 'cic/section/detail' , params );
    }

    

    async section_public_detail( id )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        return await axios.post( API_BASE + 'cic/public/lesson/info' , params );
    }

    async mysection_detail( id )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        return await axios.post( API_BASE + 'cic/mysection/detail' , params );
    }

    async section_publish( id )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        return await axios.post( API_BASE + 'cic/section/publish' , params );
    }

    async section_settings_update( id )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        params.append("sandbox_id" , this.section_sandbox_id);
        params.append("slidebox_key" , this.section_slidebox_key);
        return await axios.post( API_BASE + 'cic/section/settings/update' , params );
    }

    


    @action section_settings_init()
    {
        this.section_sandbox_id = 0;
    }

    async chapter_order_update( id , new_order )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        params.append("order_json" , JSON.stringify( new_order ));

        return await axios.post( API_BASE + 'cic/chapter/order/update' , params );
    }

    @action
    code_data_init()
    {
        this.code_lesson_id = 0;
        this.code_count = 1;
        this.code_can_use_times = 1;
        this.code_sanbox_minutes = 0;
        this.code_expire_date = moment().add(30, 'days').format("YYYY-MM-DD");
        this.code_notes = "";
    }

    async code_create()
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);

        // 开始组装数据
        params.append( "id" , this.code_lesson_id );
        params.append( "count" , this.code_count );
        params.append( "can_use_times" , this.code_can_use_times );
        params.append( "sanbox_minutes" , this.code_sanbox_minutes );
        params.append( "expire_date" , this.code_expire_date );
        params.append( "notes" , this.code_notes );
        
        return await axios.post( API_BASE + 'cic/code/create' , params );
    }

    async code_list( id )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        return await axios.post( API_BASE + 'cic/code/list' , params );
    }

    async code_set_sent( id , status )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        params.append("status" , status);
        return await axios.post( API_BASE + 'cic/code/setsent' , params );
    }

    async code_set_disabled( id )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        return await axios.post( API_BASE + 'cic/code/setdisabled' , params );
    }

    async code_get_bat_info( id )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        return await axios.post( API_BASE + 'cic/code/batinfo' , params ); 
    }

    async code_download_bat( id , markit )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        params.append("markit" , markit);
        return await axios.post( API_BASE + 'cic/code/batdownload' , params ); 
    }

    

    @action
    sandbox_data_init()
    {
        this.sandbox_type = 2; // 1= 浏览器沙箱 2 = 云沙箱
        this.sandbox_title = "";
        this.sandbox_image = "";
        this.sandbox_image_type = 1; // 1= 自定义 2= ubuntu 3= vscode
        this.sandbox_level = 4; 
        this.sandbox_entry_page = ""; 
        this.sandbox_entry_port = 8443; 
        this.sandbox_level = 4; 
    }

    async sandbox_save()
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);

        // 开始组装数据
        params.append("title" , this.sandbox_title);
        params.append("type" , this.sandbox_type);
        params.append("level" , this.sandbox_level);
        params.append("image" , this.sandbox_image);
        params.append("entry_page" , this.sandbox_entry_page);
        params.append("entry_port" , this.sandbox_entry_port);
        params.append("image" , this.sandbox_image);
        
        return await axios.post( API_BASE + 'cic/sandbox/save' , params );
    }

    async sandbox_list( deleted = 0 )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("deleted" , deleted);
        return await axios.post( API_BASE + 'cic/sandbox/list' , params );
    }

    async sandbox_detail( id )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        return await axios.post( API_BASE + 'cic/sandbox/detail' , params );
    }

    async sandbox_mydetail( id )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        return await axios.post( API_BASE + 'cic/sandbox/mydetail' , params );
    }

    @action set_sandbox_data( data )
    {
        this.sandbox_id = data.id;
        this.sandbox_title =  data.title ;
        this.sandbox_image =  data.image ;
        this.sandbox_type =  data.type ;
        this.sandbox_level =  data.level ;
        this.sandbox_entry_page =  data.entry_page ;
        this.sandbox_entry_port =  data.entry_port ;
        
        this.sandbox_published = data.published;
        this.sandbox_deleted = data.deleted;
        
    }

    async sandbox_update()
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        // 开始组装数据
        params.append("id" , this.sandbox_id);
        params.append("title" , this.sandbox_title);
        params.append("image" , this.sandbox_image);
        params.append("level" , this.sandbox_level);
        params.append("entry_page" , this.sandbox_entry_page);
        params.append("entry_port" , this.sandbox_entry_port);
        
        params.append("published" , intval(this.sandbox_published));

        return await axios.post( API_BASE + 'cic/sandbox/update' , params );
    }

    async sandbox_remove( id )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        return await axios.post( API_BASE + 'cic/sandbox/remove' , params );
    }

    async sandbox_unremove( id )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        return await axios.post( API_BASE + 'cic/sandbox/unremove' , params );
    }

    async vps_is_running( type )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("type" , type);
        return await axios.post( API_BASE + 'vps/isrunning' , params );
    }

    async vps_init( type )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("type" , type);
        return await axios.post( API_BASE + 'vps/init' , params );
    }

    async vps_ready( url )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("url" , url);
        return await axios.post( API_BASE + 'vps/ready' , params );
    
    }

    async vps_my_list()
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        return await axios.post( API_BASE + 'vps/mylist' , params );
    }

    async vps_my_time()
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        return await axios.post( API_BASE + 'cic/user/cloudtime' , params );
    }

    async vps_one_action( id , type )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        params.append("type" , type);
        return await axios.post( API_BASE + 'vps/one/action' , params );
    }

    async vps_live( appname )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("name" , appname);
        return await axios.post( API_BASE + 'vps/live' , params );
    }
    

    async lesson_save()
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        // 开始组装数据
        params.append("title" , this.lesson_title);
        params.append("subtitle" , this.lesson_subtitle);
        params.append("price_cent" , this.lesson_price * 100 );
        params.append("desp" , this.lesson_desp);

        
        params.append("expire_check_type" , this.lesson_expire_check_type);
        params.append("expire_after_buy" , this.lesson_expire_after_buy);


        params.append("sandbox_minute" , intval(this.lesson_sandbox_minute));
        params.append("promo_text" , this.lesson_promo_text);
        params.append("promo_fullprice_link" , this.lesson_promo_fullprice_link);
        params.append("platform_ci2" , this.lesson_platform_ci2);
        params.append("ci2_cate" , this.lesson_ci2_cate);
        params.append("ci2_color" , this.lesson_ci2_color);
        params.append("ci2_image_url" , this.lesson_ci2_image_url);

        return await axios.post( API_BASE + 'cic/lesson/save' , params );
    }

    async lesson_update()
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        // 开始组装数据
        params.append("id" , this.lesson_id);
        params.append("title" , this.lesson_title);
        params.append("subtitle" , this.lesson_subtitle);
        params.append("price_cent" , this.lesson_price * 100 );
        params.append("desp" , this.lesson_desp);

        
        params.append("expire_check_type" , this.lesson_expire_check_type);
        params.append("expire_after_buy" , this.lesson_expire_after_buy);


        params.append("sandbox_minute" , intval(this.lesson_sandbox_minute));
        params.append("promo_text" , this.lesson_promo_text);
        params.append("ci2_cate" , this.lesson_ci2_cate);
        params.append("ci2_color" , this.lesson_ci2_color);
        params.append("ci2_image_url" , this.lesson_ci2_image_url);
        params.append("promo_fullprice_link" , this.lesson_promo_fullprice_link);
        

        params.append("published" , intval(this.lesson_published));

        params.append("platform_ci2" , this.lesson_platform_ci2);

        return await axios.post( API_BASE + 'cic/lesson/update' , params );
    }

    async lesson_list()
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        return await axios.post( API_BASE + 'cic/lesson/list' , params );
    }

    async lesson_detail( id )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        return await axios.post( API_BASE + 'cic/lesson/detail' , params );
    }

    async lesson_mydetail( id )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        return await axios.post( API_BASE + 'cic/mylesson/detail' , params );
    }

    async lesson_remove( id )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        return await axios.post( API_BASE + 'cic/lesson/remove' , params );
    }

    async lesson_unremove( id )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        return await axios.post( API_BASE + 'cic/lesson/unremove' , params );
    }

    async lesson_exchange( id , code )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("id" , id);
        params.append("code" , code);
        return await axios.post( API_BASE + 'cic/lesson/exchange' , params );
     
    }

    async lesson_order_refresh()
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        return await axios.post( API_BASE + 'order/refresh' , params );
    }

    async lesson_netease_exchange( order_id , code )
    {
        var params = new URLSearchParams();
        params.append("token" , this.token);
        params.append("orderid" , order_id);
        params.append("buycode" , code);
        return await axios.post( API_BASE + 'cic/netease/code' , params );
     
    }

    @action set_lesson_data( data )
    {
        this.lesson_id = data.id;
        this.lesson_title =  data.title ;
        this.lesson_subtitle = data.subtitle ;
        this.lesson_price = ( data.price_cent/ 100).toFixed(2)  ;
        this.lesson_desp =  data.desp ;

        this.lesson_expire_check_type = data.expire_check_type ;
        this.lesson_expire_after_buy = data.expire_after_buy ;

        this.lesson_sandbox_minute = data.sandbox_minute;
        this.lesson_promo_text = data.promo_text;
        this.lesson_promo_fullprice_link = data.promo_fullprice_link;
        this.lesson_ci2_cate = data.ci2_cate;
        this.lesson_ci2_color = data.ci2_color;
        this.lesson_ci2_image_url = data.ci2_image_url;
        this.lesson_platform_ci2 = data.platform_ci2;

        this.lesson_published = data.published;
        this.lesson_deleted = data.deleted;
        
    }


    @action
    lesson_data_init()
    {
        // lesson to add
        this.lesson_title = "";
        this.lesson_subtitle = "";
        this.lesson_cover_url = "";
        this.lesson_price = 99.9;

        
        this.lesson_expire_check_type = 1; // 1=永久 2=指定过期日期 3=购买后N天
        this.lesson_expire_after_buy = 90; // 购买后90天
    
        this.lesson_sandbox_minute = 0;
        this.lesson_promo_text = "";
        this.lesson_promo_fullprice_link = "";

        this.lesson_desp = "";
    }

    async lesson_ci2_cates_load()
    {
        // lesson_ci2_cates
        const { data } =  await axios.get( API_BASE + 'ci2/cate' );
        if( data.data ) this.lesson_ci2_cates = data.data;
        // console.log( JSON.stringify(this.lesson_ci2_cates) );
    }



    
}

export default new CicState();

