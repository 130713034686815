import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter,Link } from 'react-router-dom';
import CicAdminLayout from '../component/CicAdminLayout';
import { Tab, Tabs, Icon, AnchorButton, FormGroup, InputGroup, Switch, RadioGroup, Radio, Checkbox, TextArea, Button, Intent, ControlGroup } from "@blueprintjs/core";
import { isApiOk , showApiError, setStringGlobal, handleStringGlobal,handleIsGlobal, toast } from '../util/Function';

@withRouter
@inject("store")
@observer
export default class CicSettings extends Component
{
    state = {"settings":[],"selected_tab":"system"};
    
    async componentDidMount()
    {
        await this.loadSettings();
    }

    async loadSettings()
    {
        const { data } = await this.props.store.settings_load();
        if( isApiOk( data ) )
        {
            this.setState({"settings":data.data});
            this.props.store.settings_set( data.data );
        }
        else showApiError( data );
    }

    selected( e )
    {
        this.setState({"selected_tab":e});
    } 

    async submit()
    {
        const { store } = this.props;
        
        const { data } = await store.settings_update();
        if( isApiOk( data ) )
        {
            toast("设置已保存");
            this.loadSettings();
        }
        else showApiError( data );

    }

    render()
    {
        const { store } = this.props;

        const system_div = <div>
        <FormGroup
            label="资源容器用域名"
        >
        <InputGroup placeholder="资源容器用域名" large="true" onChange={(e)=>handleStringGlobal(e,'settings_resouce_domain')} value={store.settings_resouce_domain} readOnly={true}/>
        </FormGroup>

        <FormGroup
            label="资源容器存储挂载路径"
        >
        <InputGroup placeholder="资源容器存储挂载路径" large="true" onChange={(e)=>handleStringGlobal(e,'settings_resouce_path')} value={store.settings_resouce_path} readOnly={true}/>
        </FormGroup>

        <FormGroup
            label="SlideBox Key"
        >
        <InputGroup type="password" placeholder="方糖云SlideBox Key" large="true" onChange={(e)=>handleStringGlobal(e,'settings_slidebox_key')} value={store.settings_slidebox_key}/>
        </FormGroup>

        <div className="std-hr form-div"/>

        <FormGroup>
        <div className="row-half">
            <div className="left">
            <Button large="true" onClick={()=>this.props.history.goBack()} >取消</Button>
            </div>
            <div className="right">
            <Button large="true" intent={Intent.PRIMARY} onClick={()=>this.submit()}>提交</Button>
            </div>
        </div>
        
        </FormGroup> 

        </div>;

        const media_div = <div>尚未开发</div>;
        
        
        const main = <div className="padbox">
            <Tabs id="sandbox-table"  selectedTabId={this.state.selected_tab} onChange={(e)=>this.selected(e)}>
        <Tab id="system" title="通用" panel={system_div} />
        <Tab id="media" title="媒体库" panel={media_div} />
        </Tabs> 
        </div>;

        const side = null;
        const title = <div className="cic-bread-nav">系统管理和设置</div>;

        return <CicAdminLayout main={main} side={side} title={title} />;
    }
}