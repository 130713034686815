import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter, Link } from 'react-router-dom';
import { HTMLTable, Checkbox, Icon, Menu, MenuItem, MenuDivider, Popover, Position, Tooltip, Button, AnchorButton  } from "@blueprintjs/core";
import { isApiOk , showApiError, setStringGlobal, handleStringGlobal,handleIsGlobal, toast, intval } from '../util/Function';


@withRouter
@inject("store")
@observer
export default class CicLessonTable extends Component
{
    async delete( id )
    {
        // lesson_remove
        if( id > 0 )
        {
            const { data } = await this.props.store.lesson_remove( id );
            if( isApiOk( data ) )
            {
                if( this.props.onDelete ) this.props.onDelete();
            }
            else
                showApiError( data ); 
        }
    }

    async undelete( id )
    {
        // lesson_remove
        if( id > 0 )
        {
            const { data } = await this.props.store.lesson_unremove( id );
            if( isApiOk( data ) )
            {
                if( this.props.onUndelete ) this.props.onUndelete();
            }
            else
                showApiError( data ); 
        }
    }
    
    
    render()
    {
        const lesson_list = this.props.data;
        if( !lesson_list ) return null;

        return <HTMLTable interactive={true} className="cic-table">
            <thead>
            <tr>
                <td className="cic-checkbox-col"><Checkbox className="cic-clean"/></td>
                <td>名称</td>
                <td className="center">定价</td>
                <td className="center">已领取</td>
                <td className="center">可用领课码</td>
                <td className="center">正版化</td>
                <td></td>
            </tr>
            </thead>

            <tbody>

            { lesson_list && lesson_list.map( item =>
                {
                    return <tr key={item.id}>
                        <td><Checkbox className="cic-clean"/></td>
                        <td>
                        {parseInt(item.published) == 1 ? <span className="published right-5">
                        {item.title}</span> : <span className="unpublished right-5">{item.title}</span>} 
                        
                        <AnchorButton target="_blank" href={"/student/lesson/index/"+item.id} small={true} minimal={true} icon="document-open" className="cic-icon" /> 
                        
                        </td>
                        <td className="center">{item.price_cent/100}</td>
                        <td className="center">{item.used_code_count}</td>
                        <td className="center">{item.code_count} 
                        
                        <Tooltip content="管理领课码" position={Position.TOP}>
                        <Button small={true} minimal={true} icon="settings" className="cic-icon left-5" onClick={()=>this.props.history.push("/admin/code/"+item.id)} /></Tooltip>
                        
                        </td>
                        <td className="center"><span className="explain">访问→点击→转化</span></td>
                        <td className="actions">
                            <Tooltip content="编辑章节" position={Position.TOP}>
                            <Button icon="manually-entered-data" className="cic-icon" onClick={()=>this.props.history.push("/admin/lesson/index/"+item.id)} small={true} minimal={true} /></Tooltip>
                            
                            
                            <Popover content={<Menu>
                
                                <MenuItem icon="edit" text="修改" onClick={()=>this.props.history.push("/admin/lesson/modify/"+item.id)} /> 
                                <MenuDivider />
                                { parseInt(item.deleted) == 1 ? <MenuItem icon="cross" text="恢复" onClick={()=>this.undelete(item.id)}/>: <MenuItem icon="cross" text="归档" onClick={()=>this.delete(item.id)}/>}
                                

                            </Menu>} position={Position.BOTTOM}>
                                <Button icon="more" className="cic-icon" small={true} minimal={true} />
                            </Popover>
                        </td>
                    </tr>
                }) 
            }

            
            
            </tbody>

            {/* <tfoot>
            <tr>
                <td className="cic-checkbox-col"><Icon icon="arrow-right" className="cic-icon" /></td>
                <td colSpan="6">归档&nbsp;&nbsp;&nbsp;恢复</td>
               
            </tr>
            </tfoot> */}


        </HTMLTable>;
    }
}