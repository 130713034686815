import React, { Component,Fragment } from 'react';

class Asciinema extends React.Component {
    static defaultProps = {
      theme: 'monokai',
      idleTimeLimit: 2,
      poster: 'npt:0:3'
    };
  
    bindRef = (ref) => {
      this.ref = ref;
    };
  
    componentDidMount() {
      window.asciinema.player.js.CreatePlayer(
        this.ref,
        this.props.src,
        this.props
      );
    }
  
    componentWillUnmount() {
      if (!this.ref) return;
  
      window.asciinema.player.js.UnmountPlayer(this.ref);
      this.ref = null;
    }
  
    render() {
      return (
        <div ref={this.bindRef}/>
      );
    }
  }