import React, { Component, Fragment } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter,Link } from 'react-router-dom';
import CicWideOneLayout from '../component/CicWideOneLayout';
import { Tab, Tabs, Icon, Position, Tooltip, Button, AnchorButton, Popover  , InputGroup, ControlGroup } from "@blueprintjs/core";
import { isApiOk , showApiError, intval, handleStringGlobal, setStringGlobal, maxorder, firstorder, toast, ftsort } from '../util/Function';
import SplitPane from 'react-split-pane';
import CicJsonPlayer from '../component/CicJsonPlayer'; 
import CicIndexBoxReadOnly from '../component/CicIndexBoxReadOnly'; 
import Iframe from 'react-iframe';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import aesjs from 'aes-js';
import md5 from 'md5';
import { Base64 } from 'js-base64';

@withRouter
@inject("store")
@observer
export default class CicSectionShow extends Component
{
    state = {"section":{},"index":[],"left_tab":"section","right_tab":"sandbox","port":3000,"copy_pop":false,"password":false,"show_right":true};

    async componentDidMount()
    {
        await this.loadData();
    }

    async loadData()
    {
        const id = intval(this.props.match.params.id);
        this.is_preview = this.props.match.params.preview && this.props.match.params.preview.toLowerCase() == 'preview' ;
        this.section_id = id;        

        if( id > 0 ) 
        {
            let ret = false;
            
            if( this.is_preview )
                ret = await this.props.store.section_detail( id );
            else
                ret = await this.props.store.mysection_detail( id );    
            
            const { data } = ret;

            if( isApiOk( data ) )
            {
                let section = data.data;

                // 公开环境下，需要进行解密
                if( !this.is_preview )
                {
                    const key = aesjs.utils.utf8.toBytes( md5(this.props.store.token).substring(0,16) );
                
                    const iv = aesjs.utils.utf8.toBytes( md5(`9iot53[89yhº•${this.props.store.uid}`).substring(0,16) );

                    var buf = Buffer.from( section.cic_published_json , 'base64');
                    var aesCbc = new aesjs.ModeOfOperation.cbc(key, iv);

                    let decryptedBytes = aesCbc.decrypt(buf);                
                    let decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
                    
                    section.cic_published_json = Base64.decode(decryptedText);
                }

                
                this.setState( {"section":section} )
                // await this.loadCode( id );
                this.props.store.section_markdown = section.cic_markdown;
                if( this.is_preview )
                {
                    this.props.store.section_json = section.cic_json;
                }
                else
                {
                    this.props.store.section_json = section.cic_published_json;
                }

                await this.loadIndex( section.lesson.id );
                
                //console.log( data.data );
            }
            else
            {
                if( data.code == 40001 ) // 权限问题
                {
                    // 转向到课程领取页面
                    this.props.history.replace("/student/lesson/exchange/"+this.section_id);
                }
                else
                {
                    showApiError( data ); 
                }
            }
                
        }
        else
        {
            toast( "课程ID错误" );
            this.props.history.goBack();
        }
    }

    async loadIndex( id )
    {
        let ret = false;
            
        if( this.is_preview )
            ret = await this.props.store.index_load( id );
        else
            ret = await this.props.store.myindex_load( id );    
        
        const { data } = ret;
        
        if( isApiOk( data ) )
        {
            this.setState( {"index":data.data} );
        }
        else
            showApiError( data );
    }

    async componentDidUpdate(prevProps)
    {
        // console.log("updated", prevProps);
        if ( this.props.match.params.id !== prevProps.match.params.id) 
        {
            await this.loadData();
            this.setState( {"left_tab":"section"} );
        }
    }

    changed( e , name )
    {
        // console.log( e.target );
        let o = {};
        o[name] = e.target.value;
        this.setState( o );
    }

    selected( e , name )
    {
        let o = {};
        o[name] = e;
        this.setState(o);
    }  

    goPort()
    {
        const appname = window.localStorage.getItem( "vps_appname" );
        if( !appname )
        {
            toast("请先启动云沙箱");
        }
        else
        {
            const url = 'http://'+this.state.port+'.'+appname+'.applinzi.com/';
            window.open( url );
        }
        
    }

    makePass()
    {
        const password = window.localStorage.getItem( "vps_pass" );
        if( !password )
        {
            toast("请先启动云沙箱");
        }
        else
        {
            this.setState( {"password":password,"copy_pop":true} );
        }
        
    }

    componentWillUnmount()
    {
        window.localStorage.removeItem( "vps_pass");
    }

    render()
    {
        if( !this.state.section.lesson ) return null;
        
        const left_width = this.state.show_right ? "40%" : "100%";
        
        const main = <div className="cic-show-box ">
        
        <div className="cic-flex-rows both-padding cic-show-height">
            
            
            <div style={{"maxWidth":left_width}}>

            <Tabs id="left-table"   selectedTabId={this.state.left_tab} onChange={(e)=>this.selected(e,"left_tab")}>
            
            <Tab  id="section" title="&nbsp;&nbsp;课程&nbsp;&nbsp;" panel={<CicJsonPlayer width={left_width} data={this.props.store.section_json} />} />

            <Tab id="index" title="&nbsp;&nbsp;目录&nbsp;&nbsp;" panel={<div className="padbox center-v std-index whiteindex">
                <CicIndexBoxReadOnly preview={this.is_preview}
        data={this.state.index} 
        lesson_id={this.state.section.lesson.id} onIndexUpdated={()=>this.loadIndex(this.state.section.lesson.id)}
        /></div>} />

        { !this.state.show_right && <Fragment><Tabs.Expander /> <Tooltip content="显示云沙箱"  position={Position.TOP}>

                    <Button onClick={()=>this.setState({"show_right":true})} icon="eye-open" className="right-5" small={true} minimal={true}/>
            </Tooltip></Fragment>}

            </Tabs> 

            </div>
                
            { this.state.show_right && 
            <div style={{"maxWidth":"60%"}}>
            
            <Tabs id="right-table"  selectedTabId={this.state.right_tab} onChange={(e)=>this.selected(e,"right_tab")}>
            
            <Tab id="sandbox" title="&nbsp;&nbsp;云沙箱&nbsp;&nbsp;" panel={<Iframe url={"/sandbox/init/"+this.state.section.sandbox_id} id="cloudsandbox"
                display="initial"
                position="relative" 
                height="100%" className="browser-box"/>} />

            {/* <Tabs.Expander /> */}

            <div className="action-icon">
                <Tooltip content="云沙箱管理（重启、关闭）"  position={Position.TOP}>
                    <AnchorButton target="_blank" href="/vps" icon="box" className="right-5" small={true} minimal={true}/>
                </Tooltip> 

                <Tooltip content="隐藏云沙箱"  position={Position.TOP}>
                    <Button onClick={()=>this.setState({"show_right":false})} icon="eye-off" className="right-5" small={true} minimal={true}/>
                </Tooltip>

                <Popover isOpen={this.state.copy_pop} content={<div>
                    <ControlGroup fill={true} vertical={false}>
                     
                    <CopyToClipboard text={this.state.password} onCopy={()=>
                    {
                        if( this.state.password )
                            toast("密码已复制到剪贴板");

                        this.setState({"copy_pop":false});  
                    }}>
                        <Button icon="shield">点此复制</Button>
                    </CopyToClipboard>
                    </ControlGroup>
                </div>}>
                <Tooltip content="复制密码"  position={Position.TOP}>
                    <Button icon="lock" className="right-5" small={true} minimal={true} onClick={()=>this.makePass()}/>
                </Tooltip>
                </Popover>

                <Tooltip content="在独立窗口打开编辑器"  position={Position.TOP}>
                    <AnchorButton target="_blank" href={"/sandbox/init/"+this.state.section.sandbox_id} icon="applications" className="right-5" small={true} minimal={true}/>
                </Tooltip>

                <Popover content={<div>
                    <ControlGroup fill={true} vertical={false}>
                        <InputGroup placeholder="输入端口..." value={this.state.port} onChange={(e)=>this.changed( e , 'port' )}/>
                        <Button onClick={()=>this.goPort()}>访问</Button>
                    </ControlGroup>
                </div>}>
                <Tooltip content="访问云沙箱内的端口"  position={Position.TOP}>
                    <Button icon="globe-network" className="right-5" small={true} minimal={true}/>
                </Tooltip>
                </Popover>

                
            </div>
             

            
            
            </Tabs>        
            </div>
            }
         </div>

        </div> 
        ;
        
        const side = null;

        const title = this.state.section.lesson ? <div className="cic-bread-nav font-16">&nbsp;&nbsp;<Link to={"/student/lesson"}>{this.state.section.lesson.title}</Link> <Icon icon="double-chevron-right" className="cic-icon" /> {this.state.section.chapter.title} <Icon icon="double-chevron-right" className="cic-icon" /> {this.state.section.title} </div>: null ;

        return <CicWideOneLayout type="student" main={main} side={side} title={title} />;
    }
}