import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter,Link } from 'react-router-dom';
import CicSiteLayout from '../component/CicSiteLayout'; 
import { Icon, ControlGroup, InputGroup, Button } from "@blueprintjs/core"; 
import { toast , isApiOk , showApiError, intval } from '../util/Function';

@withRouter
@inject("store")
@observer
export default class CicConnect extends Component
{
    state = {"code":""};
    changed( e , name )
    {
        // console.log( e.target );
        let o = {};
        o[name] = e.target.value;
        this.setState( o );
    }

    async go()
    {
        const code = intval( this.state.code );
        if( code < 1 ) return toast("错误的微信数字"); 
        
        const { data } = await this.props.store.connect( code );
        if( isApiOk( data ) )
        {
            const token = data.data;
            this.props.store.token = token;

            await this.check( token );
            
        }
        else showApiError( data );
    }

    async check( token )
    {
        // check
        const { data } = await this.props.store.checkQRCode();
        if( isApiOk( data ) )
        {
            if( data.data )
            {
                // console.log( data.data );
                data.data.result.token = token;
                const user = data.data.result;
                this.props.store.setUser( user );
                toast("欢迎 "+user.nickname );

                // 注意注意~ 这里的lesson_id 其实是 section id
                this.props.history.replace("/student/lesson/section/"+user.lesson_id);
            }
        } else showApiError( data );
    }

    render()
    {
        const main = <div className="cic-half-screen">
        
        <div className="logo-small  cic-wrapper"><img src="/image/logo.center@2x.png" alt="logo" />
        <div className="subtitle">连接显示器和键盘</div>
        </div>
        
        <div className="thebox"><div className="cic-wrapper">
        <div className="connect-box" >
        <ControlGroup vertical={false}>
            <InputGroup placeholder="输入微信推送的数字..." type="number" value={this.state.code} onChange={(e)=>this.changed( e , 'code' )} large={true}/>
            <Button onClick={()=>this.go()} large={true}>连接</Button>
        </ControlGroup></div>
        </div>     
        
        </div></div>;
        
        return <CicSiteLayout no_menu={true}>{main}</CicSiteLayout>;
    }
}