import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button, Colors, EditableText, InputGroup, Overlay } from "@blueprintjs/core";
import StdIcon from '../util/Icon';
import { toast , isApiOk , showApiError, intval } from '../util/Function';
import DragSortableList from 'react-drag-sortable';

@withRouter
@inject("store")
@observer
export default class ChapterReorder extends Component
{
    state = {"chapters":[],"lesson_id":this.props.lesson_id};

    async componentDidMount()
    {
        const lesson_id = this.props.lesson_id;
        if( lesson_id > 0 )
        {
            this.load( lesson_id );
        }
    }

    async load( lesson_id )
    {
        const { data } = await this.props.store.chapter_list( lesson_id );
        if( isApiOk( data ) )
        {
            this.setState({"chapters":data.data,"lesson_id":lesson_id});
        }
        else showApiError( data );
    }

    async onSort( new_array )
    {
        // console.log( new_array );
        let new_order = {} ;
        new_array.forEach( item => new_order[item.rank] = item.id );
        
        const { data } = await this.props.store.lesson_order_update( this.state.lesson_id , new_order );

        if( isApiOk( data ) )
        {
            this.load( this.state.lesson_id );
            if( this.props.onUpdated ) this.props.onUpdated(); 
        }
        else showApiError( data );

    }
    
    render()
    {
        const chapters  = this.state.chapters ? this.state.chapters.map( item=> { return {"content":<li>{item.title}</li>,"id":item.id}} ) :[];
        
        return <Overlay isOpen={this.props.open}><div className="overbox">
        <div className="bg">
        <div className="tright"><span className="btn" onClick={()=>this.props.onClose()}><StdIcon name="remove" /></span></div>
        
        { chapters && <ul className="orderbox-list">
                
            { Array.isArray( chapters ) && <DragSortableList items={chapters}  onSort={(e)=>this.onSort(e)} type="vertical"/>}

            </ul> }
        </div>
    </div>
    </Overlay>;
    }
}