import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

@withRouter
@inject("store")
@observer
export default class CicCenterBox extends Component
{
    render()
    {
        return <div className="cic-center-box">{this.props.children}</div>;
    }
}