import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter,Link } from 'react-router-dom';
import CicAdminLayout from '../component/CicAdminLayout'; 
import { Tab, Tabs, Icon } from "@blueprintjs/core";
import { toast , isApiOk , showApiError } from '../util/Function';
import CicSandboxTable from '../component/CicSandboxTable';

@withRouter
@inject("store")
@observer
export default class CicSanboxManage extends Component
{
    state = {"sandbox_list":[],"selected_tab":"lived"};

    async componentDidMount()
    {
        this.loadSandbox();
    }

    async loadSandbox()
    {
        const { data } = await this.props.store.sandbox_list( 1 );
        //const data = null;
        if( isApiOk( data ) )
        {
            // console.log( data.data );
            this.setState( {"sandbox_list":data.data} );
        }
        else
            showApiError( data );
    }

    selected( e )
    {
        this.setState({"selected_tab":e});
    }  
    
    render()
    {
        const main = <div className="padbox">
        <Tabs id="sandbox-table"  selectedTabId={this.state.selected_tab} onChange={(e)=>this.selected(e)}>
        <Tab id="lived" title="可用沙箱" panel={<CicSandboxTable data={this.state.sandbox_list && this.state.sandbox_list.filter( item => parseInt(item.deleted) !== 1 )} onDelete={()=>this.loadSandbox()} onUndelete={()=>this.loadSandbox()} />} />
        <Tab id="deleted" title="已归档" panel={<CicSandboxTable data={this.state.sandbox_list && this.state.sandbox_list.filter( item => parseInt(item.deleted) === 1 )} onDelete={()=>this.loadSandbox()} onUndelete={()=>this.loadSandbox()} />} />
        {/* <Tabs.Expander />
            <input className="bp3-input" type="text" placeholder="Search..." />*/}
        </Tabs> 
    </div>;

        const side = <div className="padbox cic-sidemenu"><Icon icon="plus" className="cic-icon" /><Link to="/admin/sandbox/create">创建新沙箱</Link></div>;
        
        const title = <span>沙箱管理</span>;
        return <CicAdminLayout main={main} side={side} title={title} />;
    }
}