import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter,Link } from 'react-router-dom';
import CicAdminLayout from '../component/CicAdminLayout';
import { Icon, AnchorButton, FormGroup, InputGroup, Switch, RadioGroup, Radio, Checkbox, TextArea, Button, Intent, ControlGroup, HTMLSelect } from "@blueprintjs/core";
import { isApiOk , showApiError, setStringGlobal, handleStringGlobal,handleIsGlobal, toast, intval } from '../util/Function';
import DateField from '../component/DateField'; 

@withRouter
@inject("store")
@observer
export default class CicCodeCreate extends Component
{
    state = {"lesson":{}};
    
    async componentDidMount()
    {
        const id = intval(this.props.match.params.id);
        if( id > 0 ) 
        {
            const { data } = await this.props.store.lesson_detail( id );
            if( isApiOk( data ) )
            {
                this.setState( {"lesson":data.data} );
                this.props.store.code_lesson_id = id;
            }
            else
                showApiError( data ); 
        }
        else
        {
            toast( "课程ID错误" );
            this.props.history.goBack();
        }
    }

    async submit()
    {
        const { store } = this.props;
        
        // 检查必填项
        if( store.code_count < 1 ) return toast("领课码数量不能小于1");
        if( store.code_can_use_times < 1 ) return toast("领课码使用次数不能小于1");

        const { data } = await store.code_create();
        if( isApiOk( data ) )
        {
            if( data.data )
            {
                // 清理临时课程数据
                store.code_data_init();   
                this.props.history.replace("/admin/code/"+this.state.lesson.id);
            }
        }
        else showApiError( data );

    }
    
    render()
    {
        const { store } = this.props;
        const { lesson } = this.state;

        const main = <div className="padbox">
        
        <h3>为「{lesson.title}」新增领课码</h3>
        
        <div className="std-hr form-div"/>

        <div className="cic-row">
            <div className="theone">
                <FormGroup
                label="领课码数量"
                >
                    <InputGroup placeholder="1000以内" large="true" onChange={(e)=>handleStringGlobal(e,'code_count')} value={store.code_count}/>
                </FormGroup>
            </div>
            <div className="thetwo"><FormGroup
                label="可使用次数"
                >
                    <InputGroup placeholder="1000以内" large="true" onChange={(e)=>handleStringGlobal(e,'code_can_use_times')} value={store.code_can_use_times}/>
                </FormGroup>
            </div>
            <div className="thethree"><FormGroup
                label="附送云沙箱分钟数"
                >
                    <InputGroup placeholder="1000以内" large="true" onChange={(e)=>handleStringGlobal(e,'code_sanbox_minutes')} value={store.code_sanbox_minutes}/>
                </FormGroup>
            </div>   
        </div>

        <div className="cic-row">
            <div className="theone cic-f1" style={{"maxWidth":"130px"}}>
                <FormGroup
                label="过期日期"
                >
                    <DateField value={store.code_expire_date} onChange={(value)=>store.code_expire_date=value} placeholder="选择过期日期" className="bp3-input-group"/>
                </FormGroup>
            </div>
            <div className="thetwo cic-f3"><FormGroup
                label="备注"
                >
                    <InputGroup placeholder="为谁/哪个平台/合作方创建的" large="true" onChange={(e)=>handleStringGlobal(e,'code_notes')} value={store.code_notes}/>
                </FormGroup>
            </div> 
        </div>

        <div className="std-hr form-div"/>

        <FormGroup>
        <div className="row-half">
            <div className="left">
            <Button large="true" onClick={()=>this.props.history.goBack()} >取消</Button>
            </div>
            <div className="right">

            

                <Button large="true" intent={Intent.PRIMARY} onClick={()=>this.submit()}>提交</Button>
            </div>
        </div>
        
        </FormGroup> 

        
        </div>;


        const side = null;

        const title = <div className="cic-bread-nav"><Link to={"/admin/lesson"}>课程管理</Link >  <Icon icon="double-chevron-right" className="cic-icon" /> {this.state.lesson.title} <Icon icon="double-chevron-right" className="cic-icon" /><Link to={"/admin/code/"+this.state.lesson.id}>领课码管理</Link >  <Icon icon="double-chevron-right" className="cic-icon" /> 新增</div>;

        return <CicAdminLayout main={main} side={side} title={title} />;
    }
}