import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter, NavLink } from 'react-router-dom';

@withRouter
@inject("store")
@observer
export default class CicSiteHeader extends Component
{
    render()
    {
        return <div className="cic-head-menu-box cic-wrapper " >

            <div className="left">
                <img src="/image/logo.white.png"/>{this.props.title}
            </div>

            <div className="right">
                { !this.props.no_menu && <ul className="menu-list">
                    <li><NavLink to="/home" activeClassName="active">首页</NavLink></li>
                    <li><NavLink to="/price" activeClassName="active">价格</NavLink></li>
                    <li><NavLink to="/login" activeClassName="active">注册·登入</NavLink></li>
                    
                </ul> }
                
            </div>
            

        </div>;
    }
}