import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter,Link } from 'react-router-dom';

import CicSiteLayout from '../component/CicSiteLayout'; 
import CicCenterBox from '../component/CicCenterBox'; 

@withRouter
@inject("store")
@observer
export default class CicHome extends Component
{
    render()
    {
        const main = <CicCenterBox><div className="cic-center-logo-box  cic-wrapper">
            <img src="/image/logo.center@2x.png" alt="logo" />
            <div className="subtitle">IT课程的一站式增强服务平台</div>
            <div className="explain">正版验证 · 云沙箱 · 消息推送</div>
        </div></CicCenterBox>;



        return <CicSiteLayout >{main}</CicSiteLayout>;
    }
}