import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Player from 'griffith';

@withRouter
@inject("store")
@observer
export default class CicVideoPlayer extends Component
{
    render()
    {
        return this.props.data ? <div>
            <Player sources={{"sd":{"play_url":this.props.data}}} />
        </div>: null;
    }
}