import React, { Component, Fragment } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter,Link } from 'react-router-dom';
import CicAdminLayout from '../component/CicAdminLayout';
import { Icon, AnchorButton, FormGroup, InputGroup, Switch, RadioGroup, Radio, Checkbox, TextArea, Button, Intent, ControlGroup, HTMLSelect } from "@blueprintjs/core";
import { isApiOk , showApiError, setStringGlobal, handleStringGlobal,handleIsGlobal, toast, intval } from '../util/Function';

@withRouter
@inject("store")
@observer
export default class CicSandboxModify extends Component
{
    async componentDidMount()
    {
        const id = intval(this.props.match.params.id);
        if( id > 0 )
        {
            const { data } = await this.props.store.sandbox_detail( id );
            if( isApiOk( data ) )
            {
                console.log( data.data );
                this.props.store.set_sandbox_data( data.data );
            }
            else
                showApiError( data ); 
        }
    }
    
    async submit()
    {
        const { store } = this.props;
        
        // 检查必填项
        if( store.sandbox_title.length < 1 ) return toast("沙箱名称不能为空");
        
        
        const { data } = await store.sandbox_update();
        if( isApiOk( data ) )
        {
            if( data.data && data.data.id )
            {
                // 清理临时课程数据
                store.sandbox_data_init();   
                this.props.history.replace("/admin/sandbox");
            }
        }
        else showApiError( data );

    }
    
    render()
    {
        const { store } = this.props;
        
        const main = <div className="padbox">

        {/* <FormGroup
            label="沙箱类型"
            labelFor="name"
            helperText={ parseInt(store.sandbox_type) == 1 ? "浏览器沙箱适合于纯前端项目，支持HTML、CSS和JS相关技术栈，不收取时长费用" :  "云沙箱适合于后端项目，支持Ubuntu、VSCode，可编写安装脚本，通过容器实现，按时长计费" }
            
        >
        <RadioGroup
            selectedValue={parseInt(store.sandbox_type)}
            inline={true}
            onChange={(e)=>handleStringGlobal(e,'sandbox_type')}
        >
            <Radio label="浏览器沙箱" value={1} />
            <Radio label="云沙箱" value={2} />
        </RadioGroup> 

        </FormGroup>    */}
        
        
        <FormGroup
            label="沙箱名称"
            labelFor="name"
            // labelInfo={<Link to="/">1234124</Link>} 
        >
            <InputGroup id="name" placeholder="20个字以内" large="true" onChange={(e)=>handleStringGlobal(e,'sandbox_title')} value={store.sandbox_title}/>
        </FormGroup>

        { parseInt(store.sandbox_type) == 2 && <Fragment>

        <FormGroup
            label="沙箱镜像"
            labelFor="image"
            labelInfo={<a href="http://ftqq.com" target="_blank">如何创建自定义沙箱镜像</a>} 
        >

        <div className="cic-row">
            <div className="left cic-f3">
            <InputGroup id="image" placeholder="镜像地址" large="true" onChange={(e)=>handleStringGlobal(e,'sandbox_image')} value={store.sandbox_image}/>
            </div>
            <div className="right">
            <HTMLSelect value={store.sandbox_image_type} onChange={(e)=>handleStringGlobal(e,'sandbox_image_type')} large={true}>
                <option value="1">自定义</option>
                <option value="2">Ubuntu18.04</option>
                <option value="3">VSCode Server</option>
            </HTMLSelect>
            
            </div>            
        </div>

            
        </FormGroup>

        <div className="cic-row">
            <div className="left">
                <FormGroup label="沙箱入口页面" >
                    <InputGroup placeholder="" large="true" onChange={(e)=>handleStringGlobal(e,'sandbox_entry_page')} value={store.sandbox_entry_page}/>
                </FormGroup>
            </div>
            <div className="right">
                <FormGroup label="沙箱入口端口" >
                    <InputGroup placeholder="80" large="true" onChange={(e)=>handleStringGlobal(e,'sandbox_entry_port')} value={store.sandbox_entry_port}/>
                </FormGroup>
            </div>
        </div>

        


        <FormGroup
            label="沙箱配置"
            labelFor="level"
        >
            <HTMLSelect value={store.sandbox_level} onChange={(e)=>handleStringGlobal(e,'sandbox_level')} large={true}>
                <option value="1">256M内存、2核CPU</option>
                <option value="2">512M内存、4核CPU</option>
                <option value="3">1G内存、8核CPU</option>
                <option value="4">2G内存、4核CPU</option>

            </HTMLSelect>
        </FormGroup> 

        </Fragment>}


        <RadioGroup
            selectedValue={parseInt(store.sandbox_published)}
            label="发布状态"
            labelFor="range"
            inline={true}
            onChange={(e)=>handleStringGlobal(e,'sandbox_published')}
        >
            <Radio label="草稿" value={0} />
            <Radio label="发布" value={1} />
        </RadioGroup>
        
        <FormGroup>
        <div className="row-half">
            <div className="left">
                
            </div>
            <div className="right">

            <Button large="true" onClick={()=>this.props.history.goBack()} className="right-20">取消</Button>

                <Button large="true" intent={Intent.PRIMARY} onClick={()=>this.submit()}>提交</Button>
            </div>
        </div>
        
        </FormGroup> 
        
       </div>;




        const side = null;
        const title = <div className="cic-bread-nav"><Link to="/admin/sandbox">沙箱管理</Link > <Icon icon="double-chevron-right" className="cic-icon" /> 修改沙箱设置</div>;

        return <CicAdminLayout main={main} side={side} title={title} />;
    }
}