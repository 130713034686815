import React, { Component, Fragment } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter, NavLink } from 'react-router-dom';
import UserMenu from '../component/UserMenu'; 
import UserAvatar from '../component/UserAvatar'; 
import UserName from '../component/UserName'; 

@withRouter
@inject("store")
@observer
export default class CicAdminHeader extends Component
{
    render()
    {
        return <div className="cic-admin-top-navbox">
            <div className="topbox cic-wrapper">
                <div className="left">
                    <div key="logo"><img alt="logo" src="/image/logo.banner.png" key="logobannner" id="logobannner" /></div>

                    <ul className="admin-cate">
                        <li><NavLink to="/admin/lesson" activeClassName="active">课程</NavLink></li>
                        <li><NavLink to="/admin/sandbox" activeClassName="active">沙箱</NavLink></li>
                        <li><NavLink to="/admin/student" activeClassName="active">学员</NavLink></li>
                        <li><NavLink to="/admin/settings" activeClassName="active">系统</NavLink></li>
                    </ul>

                </div>
                <div className="right">{ this.props.store.nickname && <Fragment><UserAvatar /><UserName/><UserMenu type="admin"/></Fragment> }</div>
            </div>
        </div>;
    }
}