import React, { Component,Fragment } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { toast , isApiOk , showApiError, intval } from '../util/Function';
import StdIcon from '../util/Icon';
import { Button, Colors, Icon,  EditableText, InputGroup, Overlay } from "@blueprintjs/core";
import IndexBoxChapterItem from '../component/CicIndexBoxChapterItem'; 
import IndexBoxSectionItem from '../component/CicIndexBoxSectionItem'; 
import ChapterReorder from '../component/CicChapterReorder'; 
import SectionReorder from '../component/CicSectionReorder'; 
import QRImage from '../component/QRImage';

@withRouter
@inject("store")
@observer
export default class CicIndexBox extends Component
{
    state = {"chapter_name":"","section_names":{},"index":null,"section_sort":false,"chapter_sort":false,"qr_url":false};

    componentDidMount()
    {
        // this.props.lesson_id = this.props.lesson_id;
    }

    onChange( e , name )
    {
        let o = {};
        o[name] = e.target.value;
        this.setState( o );
    }

    onStringChange( value , name )
    {
        //console.log( value );
        let o = {};
        o[name] = value;
        this.setState( o );
    }

    onSectionNameChange( chapter_id , value )
    {
        let old = this.state.section_names;
        old[chapter_id] = value;
        this.setState( old );
    }

    async sectionAdd( chapter_id , value )
    {
        //console.log( chapter_id , value );
        if( value.length > 0 && intval( chapter_id ) > 0 )
        {
            const { data } = await this.props.store.section_save( this.props.lesson_id , chapter_id , value );
            if( isApiOk( data ) )
            {
                //
                this.onSectionNameChange( chapter_id , "" );
                if( this.props.onIndexUpdated ) 
                    this.props.onIndexUpdated( data.data );
                else  
                    if( this.props.onSectionAdded ) 
                        this.props.onSectionAdded( data.data );
            }
            else showApiError( data );
        }
    }

    

    async chapterAdd( value )
    {
        if( value.length > 0 )
        {
            const { data } = await this.props.store.chapter_save( this.props.lesson_id , value );
            if( isApiOk( data ) )
            {
                //
                //console.log( this.chapter_add_text );
                this.setState({"chapter_name":""});

                if( this.props.onIndexUpdated ) 
                    this.props.onIndexUpdated( data.data );
                else    
                    if( this.props.onChapterAdded ) this.props.onChapterAdded( data.data );
                
            }
            else showApiError( data );
        }
    }

    async chapterUpdate( chapter_id , value )
    {
        if( value.length > 0 && intval(chapter_id) > 0 )
        {
            const { data } = await this.props.store.chapter_update( chapter_id , value );
            if( isApiOk( data ) )
            {
                toast("新章的名字已经保存");
                if( this.props.onIndexUpdated ) 
                    this.props.onIndexUpdated( data.data );
                else
                    if( this.props.onChapterUpdated ) this.props.onChapterUpdated( data.data );
            }
            else showApiError( data );
        }
    }

    async chapterRemove( chapter_id )
    {
        if( intval(chapter_id) > 0 )
        {
            if( window.confirm("确定要删除本章？此操作不可恢复") )
            {
                const { data } = await this.props.store.chapter_remove( chapter_id );
                if( isApiOk( data ) )
                {
                    if( this.props.onIndexUpdated ) 
                        this.props.onIndexUpdated( data.data );
                    else
                        if( this.props.onChapterRemoved ) this.props.onChapterRemoved( data.data );
                }
                else showApiError( data );
            }
            
        }
    }

    async sectionRemove( section_id )
    {
        if( intval(section_id) > 0 )
        {
            if( window.confirm("确定要删除本小节？此操作不可恢复") )
            {
                const { data } = await this.props.store.section_remove( section_id );
                if( isApiOk( data ) )
                {
                    if( this.props.onIndexUpdated ) 
                        this.props.onIndexUpdated( data.data );
                    else
                        if( this.props.onSectionRemoved ) this.props.onSectionRemoved( data.data );
                }
                else showApiError( data );
            }
            
        }
    }

    async sectionUpdate( section_id , value )
    {
        if( value.length > 0 && intval(section_id) > 0 )
        {
            const { data } = await this.props.store.section_update( section_id , value );
            if( isApiOk( data ) )
            {
                toast("新小节的名字已经保存");
                if( this.props.onIndexUpdated ) 
                    this.props.onIndexUpdated( data.data );
                else
                    if( this.props.onSectionUpdated ) this.props.onSectionUpdated( data.data );
            }
            else showApiError( data );
        }
    }

    chapterSort()
    {
        // this.state.index
        this.setState({"chapter_sort":true});
    }

    sectionReorder( chapter_id )
    {
        this.setState({"section_sort":true,"chapter_id":chapter_id});
    }

    chapterReorder( lesson_id )
    {
        this.setState({"chapter_sort":true,"lesson_id":lesson_id});
    }

    sectionEdit( section_id )
    {
        this.props.history.push("/admin/lesson/section/"+section_id+"/edit");
    }

    showQr( section_id )
    {
        const url = process.env.REACT_APP_SITE + '/student/lesson/section/landing/'+section_id;
        this.setState({"qr_url":url});
    }


    
    
    render()
    {
        let data = this.props.data ;
        if( !data ) data = {};
        const chapters = Object.values(data).sort( (a , b ) => a.order - b.order );
        
        return <ul className="chapter-index">
        { chapters.map( (item) => <Fragment key={item.id}>
            <li className="indexbox-item" >
                {/* 先显示章标题 */}
                <div className="row">
                    <div className="left">
                    <IndexBoxChapterItem data={item.title} onConfirm={value=>this.chapterUpdate( item.id , value )}/>
                    </div>
                    <div className="right">
                    <span className="action">
                        {/* <span onClick={(e)=>this.chapterSort()}>
                            <StdIcon name="sort" size={22} color={Colors.GRAY3} className="icon"/>
                        </span> */}
                        <span onClick={(e)=>this.chapterRemove( item.id )}>
                            <StdIcon name="remove" size={22} color={Colors.GRAY3} className="icon" />
                        </span>
                    </span>
                    </div>
                </div>
                
            </li>
            {/* 然后检查是否有节 */}
            {item.sections && Object.values(item.sections).sort( (a , b ) => a.order - b.order ).map( section =>  <li className="section" key={section.id}>
            {/* <EditableText value={section.title} /> */}
            <div className="row">
                <div className="left">
                <IndexBoxSectionItem data={section.title} onConfirm={value=>this.sectionUpdate( section.id , value )}/>
                </div>
                <div className="right">
                <span className="action">
                    <span onClick={e=>this.sectionEdit( section.id )}>
                        <StdIcon name="edit" size={20} color={Colors.GRAY3} className="icon"/>编辑
                    </span>
                    <span onClick={(e)=>this.showQr( section.id )}><StdIcon name="qrcode" size={16} color={Colors.GRAY3} className="icon"/>二维码</span>
                    &nbsp;
                    <span onClick={(e)=>this.sectionRemove( section.id )}>
                        <StdIcon name="remove" size={20} color={Colors.GRAY3} className="icon" />删除
                    </span>
                </span>
                </div>
            </div>
            
            </li>
            ) }
            
            {/* 最后显示新增节 */}
            <li className="section-add">
                <EditableText placeholder="+ 新增一节"  maxLength={50} value={this.state.section_names[item.id]} onChange={(value)=>this.onSectionNameChange(item.id,value)} onConfirm={(value)=>this.sectionAdd( item.id , value )}/>  
                
                { item.sections && Object.values(item.sections).length > 1 &&  <Button onClick={()=>this.sectionReorder( item.id )} className="sort-btn" icon={<StdIcon name="sort"/>} minimal={true}>小节排序</Button> }
                


            </li></Fragment>
        )}

        <li className="chapter-add">
        <EditableText placeholder="+ 新增一章"  maxLength={50} value={this.state.chapter_name} onChange={value=>this.onStringChange( value , 'chapter_name' )} onConfirm={(value)=>this.chapterAdd( value )}/>

         { chapters && chapters.length > 1 &&  <Button onClick={()=>this.chapterReorder( this.props.lesson_id )} className="sort-btn" icon={<StdIcon name="sort"/>} minimal={true}>章排序</Button> }
        
        </li>

        <li>
        
        {this.state.section_sort && <SectionReorder chapter_id={this.state.chapter_id} open={this.state.section_sort} onClose={()=>this.setState({"section_sort":false})} onUpdated={()=>this.props.onIndexUpdated()}/>}

        { this.state.chapter_sort && <ChapterReorder lesson_id={this.state.lesson_id} open={this.state.chapter_sort} onClose={()=>this.setState({"chapter_sort":false})} onUpdated={()=>this.props.onIndexUpdated()}/>}  
        
        <Overlay isOpen={this.state.qr_url}>
        
        <div className="overbox">
        <div className="section-qr">
            <div className="close-button" onClick={()=>this.setState({"qr_url":false})}><Icon icon="cross" iconSize={20} /></div>
            
            <QRImage value={this.state.qr_url} className="qrimg-box"  /> 
            
        </div>
        </div>
        
        </Overlay>
        </li>

        </ul>;
    }
}