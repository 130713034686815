import React, { Component, Fragment } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import UserMenu from '../component/UserMenu'; 
import UserAvatar from '../component/UserAvatar'; 
import UserName from '../component/UserName'; 

@withRouter
@inject("store")
@observer
export default class CicWideHeader extends Component
{
    render()
    {
        return <div className="cic-admin-top-navbox">
        <div className="topbox">
            <div className="left">
                {/* <div key="logo"><img alt="logo" src="/image/logo.banner.png" /></div> */}

                {this.props.title}

            </div>
            <div className="right">{ this.props.store.nickname && <Fragment><UserAvatar /><UserName/><UserMenu type={this.props.type}/><span className="right-10"></span></Fragment> }</div>
        </div>
    </div>;
    }
}