import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter,Link } from 'react-router-dom';
import CicAdminLayout from '../component/CicAdminLayout';
import { Icon } from "@blueprintjs/core"; 

@withRouter
@inject("store")
@observer
export default class CicStudentManage extends Component
{
    render()
    {
        const main = <div className="padbox">规划排期中…</div>;
        const side = null;
        const title = <span>学员管理</span>;

        return <CicAdminLayout main={main} side={side} title={title} />;
    }
}