import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter,Link } from 'react-router-dom';
import CicAdminLayout from '../component/CicAdminLayout';
import { Icon, AnchorButton, FormGroup, InputGroup, Switch, RadioGroup, Radio, Checkbox, TextArea, Button, Intent, ControlGroup } from "@blueprintjs/core";
import { toast , isApiOk , showApiError } from '../util/Function';

@withRouter
@inject("store")
@observer
export default class Admin extends Component
{
    state = {"uid":""};

    async submit()
    {
        const { data } = await this.props.store.sa_login( this.state.uid );
        if( isApiOk( data ) )
        {
            if( data.data )
            {
                const { result } = data.data;
                result.token = this.props.store.token;
                this.props.store.setUser( result );

                toast("欢迎 "+result.nickname );
                // 
                if( result.level < 1 )
                    this.props.history.replace("/memberonly");
                else
                {
                    if( result.level > 1 )
                        this.props.history.replace("/admin/lesson");
                    else
                        this.props.history.replace("/student/lesson"); 
                }
            }
        } else showApiError( data );
    }
    
    render()
    {
        const main = <div className="padbox">
            <FormGroup
            label="用户ID"
        >
            <InputGroup  placeholder="20个字以内" large="true" onChange={(e)=>this.setState({"uid":e.target.value})} value={this.state.uid}/>
        </FormGroup>
        <FormGroup>
        <div className="row">
        <Button large="true" intent={Intent.PRIMARY} onClick={()=>this.submit()}>提交</Button>
        </div>
        
        </FormGroup>

        </div>;


        const side = null;
        const title = <div className="cic-bread-nav"><Link to="/admin/lesson">Admin</Link > <Icon icon="double-chevron-right" className="cic-icon" /> 通过UID登入</div>;

        return <CicAdminLayout main={main} side={side} title={title} />;
    }
}