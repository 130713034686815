import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// import Login from './screen/Login';
// import LessonList from './screen/LessonList'; 
// import Home from './screen/Home'; 
import Logout from './screen/Logout'; 
// import LessonAdd from './screen/LessonAdd'; 
// import LessonDetail from './screen/LessonDetail'; 
// import LessonModify from './screen/LessonModify'; 
// import SectionEdit from './screen/SectionEdit'; 
// import SectionSettings from './screen/SectionSettings'; 
// import SectionReview from './screen/SectionReview'; 

// import LessonCode from './screen/LessonCode'; 

// ===========================
// new ones
// ===========================

import CicHome from './screen/CicHome'; 
import CicLogin from './screen/CicLogin'; 

import CicLessonManage from './screen/CicLessonManage'; 
import CicLessonCreate from './screen/CicLessonCreate'; 
import CicLessonModify from './screen/CicLessonModify'; 

import CicIndexManage from './screen/CicIndexManage'; 


import CicSanboxManage from './screen/CicSanboxManage'; 
import CicSandboxCreate from './screen/CicSandboxCreate'; 
import CicSandboxModify from './screen/CicSandboxModify'; 

import CicCodeManage from './screen/CicCodeManage'; 
import CicCodeCreate from './screen/CicCodeCreate'; 

import CicSettings from './screen/CicSettings';
import Loadable from 'react-loadable';

// import CicSectionEditor from './screen/CicSectionEditor'; 

import CicSectionSettings from './screen/CicSectionSettings'; 
import CicSectionShow from './screen/CicSectionShow'; 

import CicSandboxInit from './screen/CicSandboxInit'; 
import CicVpsManage from './screen/CicVpsManage'; 

import CicLessonSectionLanding from './screen/CicLessonSectionLanding'; 
import CicConnect from './screen/CicConnect'; 
import CicLessonExchange from './screen/CicLessonExchange'; 

import CicStudentManage from './screen/CicStudentManage'; 
import CicMyLessonList from './screen/CicMyLessonList'; 
import CicMyLessonIndex from './screen/CicMyLessonIndex';

import CicAdmin from './screen/CicAdmin'; 



const LoaderCicSectionEditor = Loadable({
  loader: () => import('./screen/CicSectionEditor').then(editor=> editor.default),
  loading() {
    return <div>Loading...</div>
  }
});






class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          {/* <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout} />
          
          
          

          <Route path="/lesson/add" component={LessonAdd} />
          <Route path="/lesson/detail/:id" component={LessonDetail} />
          <Route path="/lesson/modify/:id" component={LessonModify} />
          <Route path="/lesson/section/edit/:id" component={SectionEdit} />
          <Route path="/lesson/code/:id" component={LessonCode} />
          <Route path="/lesson/section/settings/:id" component={SectionSettings} />
          <Route path="/lesson/section/review/:id" component={SectionReview} />
          <Route path="/lesson" component={LessonList} /> */}
          
          
          
          <Route path="/admin/settings" component={CicSettings} />
          <Route path="/admin/student" component={CicStudentManage} />

          
          <Route path="/admin/code/create/:id" component={CicCodeCreate} />
          <Route path="/admin/code/:id" component={CicCodeManage} />
          
          
          <Route path="/vps" component={CicVpsManage} />
          <Route path="/sandbox/init/:id" component={CicSandboxInit} />

          <Route path="/admin/lesson/section/:id/settings" component={CicSectionSettings} />
          {/* <Route path="/admin/lesson/section/:id/edit" component={CicSectionEditor} /> */}
          <Route path="/admin/lesson/section/:id/edit" component={LoaderCicSectionEditor} />
          
          {/* 学员用 */}
          <Route path="/student/lesson/exchange/:section_id" component={CicLessonExchange} />

          <Route path="/student/lesson/section/landing/:id" component={CicLessonSectionLanding} />
          
          <Route path="/student/lesson/section/:id/:preview?" component={CicSectionShow} />
          <Route path="/student/lesson/index/:id" component={CicMyLessonIndex} />
          <Route path="/student/lesson" component={CicMyLessonList} />
          <Route path="/student/connect" component={CicConnect} />




          <Route path="/admin/login" component={CicAdmin} />
          <Route path="/admin/lesson/index/:id" component={CicIndexManage} />
          <Route path="/admin/lesson/modify/:id" component={CicLessonModify} />
          <Route path="/admin/lesson/create" component={CicLessonCreate} />
          <Route path="/admin/lesson" component={CicLessonManage} />

          <Route path="/admin/sandbox/modify/:id" component={CicSandboxModify} />
          <Route path="/admin/sandbox/create" component={CicSandboxCreate} />
          <Route path="/admin/sandbox" component={CicSanboxManage} />
          <Route path="/login" component={CicLogin} />
          <Route path="/logout" component={Logout} />
          <Route path="/" component={CicHome} />
        </Switch>
      </Router>  
    );
  }
}

export default App;
