import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import StdIcon from '../util/Icon';
import { Icon, Colors, EditableText, InputGroup } from "@blueprintjs/core";

@withRouter
@inject("store")
@observer
export default class IndexBoxSectionItem extends Component
{
    state = {"title":this.props.data};
     
    onConfirm( value )
    {
        if( this.props.onConfirm ) this.props.onConfirm( value );
    }

    render()
    {
        return <EditableText value={this.state.title} onChange={value=>this.setState({"title":value})} onConfirm={value=>this.onConfirm(value)}/>;
    }
}