import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import CicAdminHeader from '../component/CicAdminHeader'; 


import './cic.admin.scss';


@withRouter
@inject("store")
@observer
export default class CicAdminLayout extends Component
{
    render()
    {
        const main = this.props.main ? this.props.main : null; 
        const side = this.props.side ? this.props.side : null; 
        const title = this.props.title ? this.props.title : null; 

        return <DocumentTitle title={this.props.store.appname}><div className="cic-admin-box">
        
        <CicAdminHeader />

        <div className="title cic-wrapper">{title}</div>

        <div className="content cic-wrapper">
            <div className="main shadowbox">
                {main}
            </div>
            <div className="side shadowbox">
                {side}
            </div>
        </div>

        </div></DocumentTitle>;
    }
}