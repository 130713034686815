import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Tooltip, Button, TextArea, Intent, Overlay, Icon } from "@blueprintjs/core";
import { handleStringGlobal, toast } from '../util/Function';
import ReactMarkdown from 'react-markdown';
import ImageButton from '../component/ImageButton'; 

@withRouter
@inject("store")
@observer
export default class EditorButton extends Component
{
    state = {"show_fullscreen":false};

    onClose( e )
    {
        console.log( e );
    }

    uploaded( url )
    {
        //this.setState({"":});
        const field = this.props.field ? this.props.field : "lesson_desp";

        const { store } = this.props;
        if( store[field].length < 10 )
            store[field] += '![图片]('+url+')';
        else
            store[field] += "\r\n"+'![图片]('+url+')';    
    }
    
    render()
    {
        const { store } = this.props;
        const tips = this.props.title ? this.props.title : "全屏编辑";
        const field = this.props.field ? this.props.field : "lesson_desp"; 
        
        const main_button = <Icon icon="maximize" color="#CCCCCC" iconSize={18} />;

        const exit_button = <Icon icon="minimize" color="#CCCCCC" iconSize={18} />;


        return <div>
            <Tooltip content={tips}>
                    <Button icon={main_button} minimal={true} large={true} onClick={()=>this.setState({"show_fullscreen":true})} />
            </Tooltip>
            <Overlay canEscapeKeyClose={true} className="std-editor" isOpen={this.state.show_fullscreen } onClose={(e)=>this.onClose(e)}>
            <div className="fs-editor">
                <div className="fs-edit">
                    <div className="toolbar">

                        <Tooltip content="退出全屏编辑"><Button icon={exit_button} minimal={true} onClick={()=>this.setState({"show_fullscreen":false})} /></Tooltip>

                        <ImageButton markdown={true} onUploaded={(url)=>this.uploaded(url)} title="上传图片" />&nbsp;

                        
                    
                    </div>
                    <div className="p5">
                        <TextArea className="fs-textarea" large={true} placeholder="详细介绍课程的内容、优势、师资、适用人群、学习基础。支持Markdown语法，可上传图片，图片宽度建议800px。" onChange={(e)=>handleStringGlobal(e,field)} value={store[field]}>
                        
                        </TextArea>
                    </div>
                    
                </div>
                <div className="fs-preview">
                    <ReactMarkdown source={store[field]} />
                </div>
            </div>
            </Overlay>
        </div>;
    }
}