import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter,Link } from 'react-router-dom';
import { isApiOk , showApiError, setStringGlobal, handleStringGlobal,handleIsGlobal, toast, intval, is_in_iframe, is_security_domain } from '../util/Function';
import { Tab, Tabs, Icon, Position, Tooltip, Button, ProgressBar  } from "@blueprintjs/core";
import Iframe from 'react-iframe';
import IdleTimer from 'react-idle-timer';
import {CopyToClipboard} from 'react-copy-to-clipboard';

@withRouter
@inject("store")
@observer
export default class CicSandboxInit extends Component
{
    state = {"sandbox":false,"url":"","password":"","checking":false,"done":false,"inited":false};

    async componentDidMount()
    {
        // 首先获得沙箱id
        const id = intval(this.props.match.params.id);
        if( id > 0 )
        {
            const { data } = await this.props.store.sandbox_mydetail( id );
            if( isApiOk( data ) )
            {
                // console.log( data.data );
                this.setState( {"sandbox":data.data} )

                await this.vps_is_running( id );
            }
            else
                showApiError( data );
        }else
        {
            // toast( "ID错误" );
            // this.props.history.goBack();
        }
    }

    

    async vps_is_running( id )
    {
        const { data } = await this.props.store.vps_is_running( id );
        if( isApiOk( data ) )
        {
            // 如果数据为空，data.data === false
            if( data.data )
            {
                const vps = data.data;
                this.setState( {"url":'http://'+vps.entry_port+ '.' + vps.appname + '.applinzi.com/'+vps.entry_page,"password":vps.password,"checking":false,"done":true,"appname":vps.appname,"inited":true} );

                window.localStorage.setItem( "vps_pass" , vps.password );
                window.localStorage.setItem( "vps_appname" , vps.appname );

                this.golive();
            }
            
            
        }
        else
            showApiError( data );
    }

    golive()
    {
        // console.log( "go live " );

        this.ht = window.setInterval( ()=>this.props.store.vps_live(this.state.appname) , 1000*60*1 ); // 1分钟更新一次心跳

    }

    async vps_init( type )
    {
        if( is_in_iframe() && is_security_domain() )
        {
            if( window.confirm( "云沙箱不能在https下嵌入页面，是否打开独立窗口？" ))
            {
                window.open(window.self.location.href.replace('https://','http://'), "_blank");
                return false;
            }
        }
        
        this.setState({"inited":true});
        
        const { data } = await this.props.store.vps_init( type );
        if( isApiOk( data ) )
        {
            // console.log( data );
            const vps = data.data;
            this.setState( {"url":'http://'+vps.entry_port+ '.' + vps.appname + '.applinzi.com/'+vps.entry_page,"password":vps.password,"checking":true,"done":false,"appname":vps.appname,"inited":true} );

            window.localStorage.setItem( "vps_pass" , vps.password );
            window.localStorage.setItem( "vps_appname" , vps.appname );

            window.setTimeout( ()=>this.checking() , 100 ); // 快速检测
            this.ck = window.setInterval( ()=>this.checking() , 1000*5 ); // 每5秒检测一次
        }
        else
            showApiError( data ); 
    }

    async checking()
    {
        const { data } = await this.props.store.vps_ready(this.state.url);
        if( isApiOk( data ) )
        {
            // console.log( data );
           
            if( data.data.ready == 1 )
            {
                this.props.store.vps_password = this.state.password;
                
                this.setState({"done":true});
                window.clearInterval( this.ck );

                this.golive();
                
                // console.log( "VNC" , this.props.store.vnc_password );
            }
        }
        else
            showApiError( data );
    }

    componentWillUnmount()
    {
        window.clearInterval(this.ht);
        window.localStorage.removeItem( "vps_pass");
    }

    onIdle()
    {
        console.log("发呆过长，关闭云桌面心跳");
        window.clearInterval(this.ht);
        if( window.confirm( "长时间未活动，云沙箱已进入待关闭状态，重新激活？" ) )
        {
            this.onActive();
        }
        
    }

    onActive()
    {
        console.log("重新活动，打开云桌面心跳");
        this.golive();
        toast("桌面状态已重新激活");
    }

    
    render()
    {
        return <div className="cic-center-box cic-fullscreen-height">

        {/* 
         <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
		  onActive={()=>this.onActive()}
          onIdle={()=>this.onIdle()}
          debounce={250}
          timeout={1000 * 60 * 30 } />
         */}

        { this.state.inited ?
        // 已经点击过初始化按钮
             this.state.done ?
                <div className="browser-box vps-box">
                    <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onActive={()=>this.onActive()}
                    onIdle={()=>this.onIdle()}
                    debounce={250}
                    timeout={1000 * 60 * 60 * 1 } />
                    <div className="password">
                    <Tooltip content="复制密码" position={Position.LEFT}>  
                        <CopyToClipboard text={this.state.password} onCopy={()=>
                            {
                                toast("密码已复制到剪贴板");
                            }}>
                                <Icon icon="shield" className="cic-icon" />
                        </CopyToClipboard> 
                    </Tooltip> 
                        </div>
                    <Iframe url={this.state.url} 
                        display="initial"
                        position="relative" height="100%" className="frame" />
                </div>
            :
                <div>
                    <h3>正在初始化云沙箱... ✨</h3>
                    <div><ProgressBar/></div> 
                </div>    
            
        : 
        // 从未点击初始化按钮
             this.state.sandbox.id > 0 ?
                <div className="init-button-box">
                    <div>因新浪云接口调整，云沙箱暂停服务，请<a href="https://docs.docker.com/get-docker/" target="_blank">安装并启动docker后</a>，通过以下以下命令在本地使用： 
                        <div style={{"padding":"20px"}}><code>docker run -e {this.state.sandbox.pass_field}="123456" -p 8443:8443 -p 3000:3000 {this.state.sandbox.image}</code></div>
                        然后访问 http://localhost:8443 ，使用密码 123456 登入
                    </div>
                    {/* <Button icon="box" large={true} text={"启动云沙箱[S"+this.state.sandbox.id+"]"} onClick={()=>this.vps_init( this.state.sandbox.id )} />
                <div className="explain">
                    如果出现沙箱密码多次输入仍提示错误，请调整您浏览器的「SameSite」设置。<a href="https://get.ftqq.com/618.card" target="_blank">点此查看如何操作</a>
                </div> */}

                </div> 
            : 
                <h3>此课程没有配置云沙箱</h3> 
        } 
        
       
            
        </div>;
    }
}