import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import CicSiteLayout from '../component/CicSiteLayout'; 
import { toast , isApiOk , showApiError, intval } from '../util/Function';

@withRouter
@inject("store")
@observer
export default class CicLessonSectionLanding extends Component
{
    state = {"qr_url":"","done":false,"expired":false};

    async componentDidMount()
    {
        const id = intval(this.props.match.params.id);
        this.lesson_id = id;
        if( id > 0 )
        {
            // 将课程id作为参数传给登入二维码
            const { data } = await this.props.store.loadQRCode2( id );
            if( isApiOk( data ) )
            {
                if( data.data.qr_url )
                {
                    this.setState({"qr_url":data.data.qr_url});
                    this.props.store.token = data.data.token;

                    // 添加定时检测任务
                    this.check = setInterval( ()=>{this.checkLogin()} , 1000*5 );

                    window.setTimeout(() => {
                        this.setState({"expired":true});
                    }, 1000*60);
                }
                    
                // toast("hello");
                // console.log( data );
            }
            else showApiError( data );
        } 
        
        
        
    }

    async checkLogin()
    {
        const { data } = await this.props.store.checkQRCode();
        if( isApiOk( data ) )
        {
            if( data.data )
            {
                this.cleanCheck();
                // this.props.store.setUser( data.data );
                // toast("欢迎 "+data.data.nickname );
                this.setState( {"done":true} );
            }
        } else showApiError( data );
    }

    cleanCheck()
    {
        clearInterval( this.check );
    }

    componentWillUnmount()
    {
        this.cleanCheck();
    }
    
    render()
    {
        const main = <div className="cic-half-screen">
        
        <div className="logo-small  cic-wrapper"><img src="/image/logo.center@2x.png" alt="logo" />
        <div className="subtitle">欢迎使用</div>
        </div>
        
        <div className="thebox "><div className="cic-wrapper">
        { this.state.done ? <h3>请按您微信收到的消息提示连接显示器和键盘</h3> : 
            this.state.qr_url && this.state.qr_url.length > 0 && ( this.state.expired ? <h3>二维码已过期，请刷新页面重试</h3>  : <div><img src={this.state.qr_url} className="qrcode" alt="sample-qr-code"/>
            <p className="explain">请长按图片识别二维码，关注后登入</p></div> ) 
            
            
        }
        
        </div>     
        
        </div></div>;
        return <CicSiteLayout no_menu={true} >{main}</CicSiteLayout>;
    }
}