import React, { Component, Fragment } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import CenterLayout from '../component/CenterLayout'; 
import CicSiteLayout from '../component/CicSiteLayout'; 
import { toast , isApiOk , showApiError } from '../util/Function';

@withRouter
@inject("store")
@observer
export default class Login extends Component
{
    state = {"qr_url":""};

    async componentDidMount()
    {
        const { data } = await this.props.store.loadQRCode();
        if( isApiOk( data ) )
        {
            if( data.data.qr_url )
            {
                this.setState({"qr_url":data.data.qr_url});
                this.props.store.token = data.data.token;

                // 添加定时检测任务
                this.check = setInterval( ()=>{this.checkLogin()} , 1000*5 );
            }
                
            // toast("hello");
            // console.log( data );
        }
        else showApiError( data );
    }

    async checkLogin()
    {
        const { data } = await this.props.store.checkQRCode();
        if( isApiOk( data ) )
        {
            if( data.data )
            {
                this.cleanCheck();

                const { result } = data.data;
                result.token = this.props.store.token;
                this.props.store.setUser( result );

                toast("欢迎回来，"+result.nickname);
                // 
                if( result.level < 1 )
                    this.props.history.replace("/memberonly");
                else
                {
                    if( result.level > 1 )
                        this.props.history.replace("/admin/lesson");
                    else
                        this.props.history.replace("/student/lesson"); 
                }
                       
            }
        } else showApiError( data );
    }

    cleanCheck()
    {
        clearInterval( this.check );
    }

    componentWillUnmount()
    {
        this.cleanCheck();
    }
    
    render()
    {
        const main = <div className="cic-half-screen">
        
        <div className="logo-small  cic-wrapper"><img src="/image/logo.center@2x.png" alt="logo" />
        <div className="subtitle">欢迎使用</div>
        </div>
        
        <div className="thebox ">
            <div className="cic-wrapper">
            {this.state.qr_url && this.state.qr_url.length > 0 && <img src={this.state.qr_url} className="qrcode" alt="sample-qr-code"/>}
        <p className="explain">请用微信扫描二维码，关注后自动登入</p>

            </div>
        
        </div></div>;
        return <CicSiteLayout>{main}</CicSiteLayout>;
    }
}