import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { HTMLTable, Checkbox, Icon, Menu, MenuItem, MenuDivider, Popover, Position, Button  } from "@blueprintjs/core";
import { isApiOk , showApiError, setStringGlobal, handleStringGlobal,handleIsGlobal, toast, intval } from '../util/Function';
import {CopyToClipboard} from 'react-copy-to-clipboard';

@withRouter
@inject("store")
@observer
export default class CiCVpsTable extends Component
{
    async vps_action( id , action )
    {
        if( !window.confirm( "云沙箱中的数据会全部重置，确定要"+action+"云沙箱环境["+id+"]吗？" ) ) return false;
        
        
        
        if( id > 0 )
        {
            const { data } = await this.props.store.vps_one_action( id , action );

            if( isApiOk( data ) )
            {
                console.log("action OK");
                if( this.props.onUpdate ) this.props.onUpdate();
            }
            else
                showApiError( data ); 
        }
    }
    
    render()
    {
        const vps_list = this.props.data;
        if( !vps_list ) return null;
        
        return <HTMLTable interactive={true} className="cic-table">
            <thead>
            <tr>
                <td className="cic-checkbox-col" style={{"width":"50px"}}><Checkbox className="cic-clean"/></td>
                <td className="center" style={{"width":"50px"}}>id</td>
                <td>URL</td>
                <td className="center" style={{"width":"80px"}}>密码</td>
                <td style={{"width":"200px","textAlign":"right"}}></td>
            </tr>
            </thead>

            <tbody>

            { vps_list && vps_list.map( item =>
                {
                    return <tr key={item.id}>
                        <td><Checkbox className="cic-clean"/></td>
                        
                        <td className="center">{item.id}</td>
                        
                        <td ><a target="_blank" href={"http://"+item.entry_port+"."+item.appname+".applinzi.com/"+item.entry_page}>{"http://"+item.entry_port+"."+item.appname+".applinzi.com/"+item.entry_page}</a></td>

                        <td className="center">
                        <CopyToClipboard text={item.password} onCopy={()=>
                        {
                            toast("密码已复制到剪贴板");  
                        }}>
                        <Button icon="duplicate" small={true} minimal={true}/>
                        </CopyToClipboard>
                        </td>
                        
                        <td className="actions" style={{"width":"200px","textAlign":"right"}}>
                                <Button icon="cross"  small={true} minimal={true} text="关闭" onClick={()=>this.vps_action( item.id, 'stop')}/>
                            
                                <Button icon="history"   small={true} minimal={true} text="重启" onClick={()=>this.vps_action( item.id, 'restart')}/>
                            
                        </td>
                    </tr>
                }) 
            }

            
            
            </tbody>

            {/* <tfoot>
            <tr>
                <td className="cic-checkbox-col"><Icon icon="arrow-right" className="cic-icon" /></td>
                <td colSpan="6">归档&nbsp;&nbsp;&nbsp;恢复</td>
               
            </tr>
            </tfoot> */}


        </HTMLTable>;
    }
}