import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter,Link } from 'react-router-dom';
import CicAdminLayout from '../component/CicAdminLayout'; 
import { Icon, AnchorButton, FormGroup, InputGroup, Switch, RadioGroup, Radio, Checkbox, TextArea, Button, Intent, ControlGroup,HTMLSelect } from "@blueprintjs/core";
import { isApiOk , showApiError, setStringGlobal, handleStringGlobal,handleIsGlobal, toast, intval } from '../util/Function';
import ImageButton from '../component/ImageButton'; 
import EditorButton from '../component/EditorButton'; 


@withRouter
@inject("store")
@observer
export default class CicLessonModify extends Component
{
    async componentDidMount()
    {
        const id = intval(this.props.match.params.id);
        if( id > 0 )
        {
            const { data } = await this.props.store.lesson_detail( id );
            if( isApiOk( data ) )
            {
                console.log( data.data );
                this.props.store.set_lesson_data( data.data );
                this.props.store.lesson_ci2_cates_load();
            }
            else
                showApiError( data ); 
        }
    }

    uploaded( url )
    {
        //this.setState({"":});
        const { store } = this.props;
        store.lesson_desp += "\r\n"+'![图片]('+url+')';
    }

    async submit()
    {
        const { store } = this.props;
        // 检查必填项
        if( store.lesson_title.length < 1 ) return toast("课程名称不能为空");
        if( store.lesson_subtitle.length < 1 ) return toast("课程副标题不能为空");
        if( store.lesson_price.length < 1 ) return toast("课程价格不能为空");
        if( store.lesson_desp.length < 1 ) return toast("课程介绍不能为空");
        
        if( store.lesson_promo_text.length < 1 ) return toast("引导文案不能为空");
        if( store.lesson_promo_fullprice_link.length < 1 ) return toast("购买链接不能为空");
        
        const { data } = await store.lesson_update();
        if( isApiOk( data ) )
        {
            if( data.data && data.data.id )
            {
                // 清理临时课程数据
                store.lesson_data_init();   
                this.props.history.replace("/admin/lesson");
            }
            //console.log( data );
        }
        else showApiError( data );
       
        // console.log( store );
    }


    
    render()
    {
        const { store } = this.props;
        
        const main = <div className="padbox">
        <FormGroup
            label="课程名称"
            labelFor="name"
            labelInfo="(必填)" 
        >
            <InputGroup id="name" placeholder="20个字以内" large="true" onChange={(e)=>handleStringGlobal(e,'lesson_title')} value={store.lesson_title}/>
        </FormGroup>

        <FormGroup
            label="课程副标题"
            labelFor="subtitle"
            labelInfo="(必填)" 
        >
            <InputGroup id="subtitle" placeholder="20个字以内" large="true" onChange={(e)=>handleStringGlobal(e,'lesson_subtitle')} value={store.lesson_subtitle}/>
        </FormGroup>

        <FormGroup
            label="课程定价"
            labelFor="price"
            labelInfo="(必填)" 
        >
        <InputGroup id="price" placeholder="299.9" large="true" onChange={(e)=>handleStringGlobal(e,'lesson_price')} value={store.lesson_price}/>
        </FormGroup>

        <FormGroup
            label="默认赠送的云沙箱分钟数"
            labelFor="sandbox_minute"
            labelInfo="(必填)" 
        >
        <InputGroup id="sandbox_minute" placeholder="0为不赠送，创建领课码时可以覆盖本设定" large="true" onChange={(e)=>handleStringGlobal(e,'lesson_sandbox_minute')} value={store.lesson_sandbox_minute}/>
        </FormGroup>

        <RadioGroup
            selectedValue={parseInt(store.lesson_expire_check_type)}
            label="课程有效期检查"
            labelFor="range"
            inline={true}
            onChange={(e)=>handleStringGlobal(e,'lesson_expire_check_type')}
        >
            <Radio label="不自动检查过期" value={1} />
            <Radio label="购买后N天自动过期" value={2} />
        </RadioGroup>

        <div className="std-hr form-div"/>
        <FormGroup>
        <Checkbox checked={parseInt(store.lesson_platform_ci2) === 1} onChange={(e)=>handleIsGlobal( e , 'lesson_platform_ci2' )}>
        是否课猫学院课程
        </Checkbox>
        </FormGroup>

        { store.lesson_platform_ci2 == 1 && <FormGroup
            label="课猫课程分类"
        >
            <HTMLSelect value={store.lesson_ci2_cate} onChange={(e)=>handleStringGlobal(e,'lesson_ci2_cate')} large={true}>

            <option key="theone" value="">-请选择分类-</option>
            
            { store.lesson_ci2_cates && store.lesson_ci2_cates.length > 0 && store.lesson_ci2_cates.map( item => <option key={item.link} value={item.link}>{item.text}</option> ) }       

            </HTMLSelect>

            <FormGroup className="top10"
            label="课程卡片背景色"
            >
            <InputGroup placeholder="样式英文名，如 yellow,blue,red,navy" large="true" onChange={(e)=>handleStringGlobal(e,'lesson_ci2_color')} value={store.lesson_ci2_color}/>
            </FormGroup>

            <FormGroup
            label="课程卡片背景图片"
            >
            <InputGroup placeholder="300*150,600*300最佳。放置在背景色之上、文字之下" large="true" onChange={(e)=>handleStringGlobal(e,'lesson_ci2_image_url')} value={store.lesson_ci2_image_url}/>
            </FormGroup>



        </FormGroup>  }

        <div className="std-hr form-div"/>

        <FormGroup
            label="正版化引导"
            labelFor="promo"
            labelInfo="(必填)" 
        >

        <div className="cic-row">
            <div className="left">
                <InputGroup id="promo_text" placeholder="引导文案" large="true" onChange={(e)=>handleStringGlobal(e,'lesson_promo_text')} value={store.lesson_promo_text}/>
            </div>

            <div className="right cic-f2">
                <InputGroup id="promo_fullprice_link" placeholder="购买跳转链接" large="true" onChange={(e)=>handleStringGlobal(e,'lesson_promo_fullprice_link')} value={store.lesson_promo_fullprice_link}/>
            </div>
        </div>

        </FormGroup>

        <div className="std-hr form-div"/>

        <RadioGroup
            selectedValue={parseInt(store.lesson_published)}
            label="发布状态"
            labelFor="range"
            inline={true}
            onChange={(e)=>handleStringGlobal(e,'lesson_published')}
        >
            <Radio label="草稿" value={0} />
            <Radio label="发布" value={1} />
        </RadioGroup>

        <FormGroup
            label="课程简介"
            labelFor="desp"
            labelInfo="(必填)"
        >
        <TextArea className="std-area" large="true" placeholder="详细介绍课程的内容、优势、师资、适用人群、学习基础。支持Markdown语法，可上传图片，图片宽度建议800px。" onChange={(e)=>handleStringGlobal(e,'lesson_desp')} value={store.lesson_desp}/>
        </FormGroup>

        <FormGroup>
        <div className="row-half">
            <div className="left">
                <EditorButton /><ImageButton markdown={true} onUploaded={(url)=>this.uploaded(url)} title="上传图片"/>
            </div>
            <div className="right">
                <Button large="true" onClick={()=>this.props.history.goBack()} className="right-10">取消</Button>

                <Button large="true" intent={Intent.PRIMARY} onClick={()=>this.submit()}>提交</Button>
            </div>
        </div>
        
        </FormGroup>
        
        
        </div>;
        const side = null;
        const title = <div className="cic-bread-nav"><Link to="/admin/lesson">课程管理</Link > <Icon icon="double-chevron-right" className="cic-icon" /> 修改基本信息</div>;

        return <CicAdminLayout main={main} side={side} title={title} />;
    }
}