import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Icon, Tooltip, Position, Button } from "@blueprintjs/core";
import ImageZoom from 'react-medium-image-zoom';
import SplitPane from 'react-split-pane';
import ReactMarkdown from 'react-markdown';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { solarizedDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { isApiOk , showApiError, setStringGlobal, handleStringGlobal,handleIsGlobal, toast, intval } from '../util/Function';
import $ from 'jquery';
import Sound from 'react-sound';
import CicVideoPlayer from '../component/CicVideoPlayer'; 
import Asciinema from '../component/Asciinema';

// import 'asciinema-player/resources/public/js/asciinema-player.js';
// import 'asciinema-player/resources/public/css/asciinema-player.css';


function LinkRenderer(props) 
{
    return <a href={props.href} target="_blank">{props.children}</a>
}

function ImageZoomed( props )
{
    return <ImageZoom  
    image={{ src: props.src }} 
    />
}


@withRouter
@inject("store")
@observer
export default class CicJsonPlayerPage extends Component
{
    // state = {"play_list":false,"play_on":0,"play_on_url":"","playing":false,"playstatus":Sound.status.PAUSED};
    
    onPre()
    {
        if( this.props.onPre ) this.props.onPre();
    }

    onNext()
    {
        if( this.props.onNext ) this.props.onNext();
    }

    audio_init()
    {
        const action_list = this.props.data.filter( item => item.type.toLowerCase() == 'text' && item.audio_url && item.audio_url.length > 0 );

        console.log( "new init" , action_list );
        
        
        if( action_list ) 
        {
            this.props.store.set_player_action_data( action_list );
        }
        else
        {
            this.props.store.player_status = Sound.status.PAUSED;
        }
            
    }

    async componentDidMount()
    {
        this.addReadClick();
        this.audio_init();
    }

    async componentDidUpdate(prevProps)
    {
        // console.log("updated", prevProps);
        if ( this.props.data !== prevProps.data) 
        {
            this.removeReadClick();
            this.addReadClick();
            this.audio_init();
        }
    }

    addReadClick()
    {
        $(".textaction >*").on( "dblclick" , function(){$(this).toggleClass("readclick")} );
        //$(".textaction >*").on( "dblclick" ,  ()=>this.alert );
    }

    removeReadClick()
    {
        $(".textaction >*").removeClass("readclick");
        $(".textaction >*").off( "dblclick" , function(){$(this).toggleClass("readclick")} );
    }

    componentWillUnmount()
    {
        this.removeReadClick();
    }

    componentDidCatch(error, errorInfo) 
    {
        // console.log( error , info );
    }

    play()
    {
        const store = this.props.store;
        store.player_playing = true;
        store.player_status = Sound.status.PLAYING;
    }
    
    pause()
    {
        const store = this.props.store;
        store.player_playing = false;
        store.player_status = Sound.status.PAUSED;

    }

    next_action()
    {
        const store = this.props.store;
        const count = store.player_action_list.length;
        const now_id = store.player_action_current_number;
        if( now_id + 1 >= count )
        {
            // store.player_action_current_number = 0;
            this.onNext();
        }
        else
        {
            store.player_action_current_number++;
        }
    }

    // resume()
    // {
    //     this.setState( { "playstatus":Sound.status.PLAYING , "playing":true } );
    // }
    
    render()
    {
        if( !this.props.data ) return null;

        let slide_info = this.props.data.filter( item => item.type.toLowerCase() == 'slide' )[0];

        if( !slide_info ) slide_info = {
            "type":"slide",
            "content":"/image/logo.center.png"
        };

        const others_info = this.props.data.filter( item => item.type.toLowerCase() != 'slide' );

        let count = 0;

        const store = this.props.store;
        
        // console.log( audio_to_play );
        const fullwidth = this.props.width == '100%' ? " full ":" half40";

        return <div className="cic-json-page editor" >
            <SplitPane split="horizontal" minSize={50} defaultSize={ parseInt(localStorage.getItem('splitPos'), 10) > 0 ? parseInt(localStorage.getItem('splitPos'), 10) : 400 }
               onChange={ size => localStorage.setItem('splitPos', size) }>
                <div className="top">
                    { slide_info && <div className="image-box"><ImageZoom image={{ src: slide_info.content }}  /></div> }
                </div>
                <div className="bottom" >
                    <div className={"toolbar "+fullwidth} >
                        <div className="left">
                            {store.player_action_list && store.player_action_list.length > 0 &&
                            <Tooltip content="播放语音" position={Position.RIGHT}>
                            
                            {
                                store.player_playing ? <Button icon="pause" minimal={true} onClick={()=>this.pause()}/>  
                                : 
                                
                                <Button icon="play" minimal={true} onClick={()=>this.play()}/> 
                            }
                            
                            
                            </Tooltip> }
                            {/* <Button icon="refresh" minimal={true}/> */}

                            { store.player_action_current_url && <Sound  
                                url={store.player_action_current_url} 
                                
                                playStatus={store.player_status}

                                // onPlaying={()=>this.setState({"playing":true})}

                                // onResume={()=>this.setState({"playing":false})}

                                // onStop={()=>this.setState({"playing":false})}
                                
                                
                                // playbackRate={this.props.store.play_sound_rate}
                                onFinishedPlaying={()=>this.next_action()}
                                /> }
                             
                            
                        </div>
                        <div className="right">
                            
                            <Tooltip content="向前翻页" position={Position.LEFT}>
                            <Button onClick={()=>this.onPre()} icon="chevron-left" minimal={true} />
                            </Tooltip> 

                            <Tooltip content="向后翻页" position={Position.LEFT}>
                            <Button onClick={()=>this.onNext()} icon="chevron-right" minimal={true}/>
                            </Tooltip>

                           
                        </div>
                    </div>
                    <div className="content">
                        <div className="block">
                        { others_info && others_info.map(
                            (item) =>
                            {
                                count++;

                                if( item.type == 'text' )
                                {
                                    if( item.audio_url )
                                    console.log( "audio_url" , item.audio_url );
                                    
                                    return <ReactMarkdown className="textaction" key={count} source={item.content} renderers={{link: LinkRenderer,image:ImageZoomed}}/>
                                }
                                    

                                if( item.type == 'code' ) 
                                    return <div className="codeaction"  key={count}>
                                        <SyntaxHighlighter className="codeblock" data-lang={item.language} language={item.language} 
                                        wrapLines={true}
                                        style={solarizedDark}>
                                        {item.content}
                                        </SyntaxHighlighter> 
                                        <CopyToClipboard text={item.content} onCopy={()=>
                                        {
                                            toast("代码已复制到剪贴板");
                                        }}><Button icon="duplicate" minimal={true} text="复制代码" /></CopyToClipboard> 
                                  </div>

                                if( item.type == 'video' )
                                {
                                    return <div key={count}className="video-box"><CicVideoPlayer data={item.content} /></div>
                                }
                                   
                                  
                                // 稍后添加，目前存在边界溢出和跨域载入的问题，稍后处理。
                                //   if( item.type == 'acast' ) 
                                //   return <div key={count}className="acast-box"><Asciinema-player   src={item.content} /></div> 
                                  
                            }
                        ) }
                        </div>   
                    </div>
                </div>
                
            </SplitPane>
            
        </div>;
    }
}