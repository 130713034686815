import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter,Link } from 'react-router-dom';
import CicSiteLayout from '../component/CicSiteLayout';
import { Icon, Button, AnchorButton, Popover, ControlGroup, InputGroup, Position} from "@blueprintjs/core";
import { isApiOk , showApiError, intval, handleStringGlobal, setStringGlobal, toast } from '../util/Function'; 

@withRouter
@inject("store")
@observer
export default class CicLessonExchange extends Component
{
    
    state = {"section":{},"excode":""};

    componentDidMount()
    {
        this.loadSection();

    }

    async loadSection()
    {
        const id = intval(this.props.match.params.section_id);
        if( id > 0 ) 
        {
            const { data } = await this.props.store.section_public_detail( id );    
            
            if( isApiOk( data ) )
            {
                this.setState( {"section":data.data} );
                // console.log( data.data );
            }
            else
                showApiError( data );
        
        }
    }

    changed( e , name )
    {
        // console.log( e.target );
        let o = {};
        o[name] = e.target.value;
        this.setState( o );
    }

    async exchage( lesson_id )
    {
        if( lesson_id )
        {
            const { data } = await this.props.store.lesson_exchange( lesson_id , this.state.excode );    
            
            if( isApiOk( data ) )
            {
                // 课程兑换成功，页面转向到对应的小节
                this.props.history.replace("/student/lesson/section/"+this.state.section.id);
            }
            else
                showApiError( data );
        }
    }
    
    render()
    {
        if( !this.state.section.lesson ) return null;
        const main = <div className="cic-wrapper exchage-page">
        
        <div className="exchange-box">
            <div className="left-logo">
                <img src="/image/logo.center@2x.png" alt="logo" />
            </div>
            <div className="right-content">
                <div className="notice">
                    您尚未领取该课程。如果在您购买课程的时，没收到「领课码」，请<a href={this.state.section.lesson.promo_fullprice_link} target="_blank">咨询购买平台</a>
                </div>
                <div className="action">
                    
                    <Popover content={<div>
                        <ControlGroup fill={true} vertical={false}>
                            <InputGroup placeholder="输入领课码" value={this.state.excode} onChange={(e)=>this.changed( e , 'excode' )}/>
                            <Button onClick={()=>this.exchage( this.state.section.lesson.id )}>领取</Button>
                        </ControlGroup>
                    </div>} position={Position.BOTTOM} ><Button text="输入领课码" large={true} className="wide"/> 
                    </Popover>

                    


                    <AnchorButton target="_blank" href={this.state.section.lesson.promo_fullprice_link} text={this.state.section.lesson.promo_text} large={true} minimal={true} className="line-button wide"/>
                </div>
            </div>
        </div>
        </div>;

        const side = null;
        const title = <span className="lesson_title_large"><Icon icon="cross" iconSize={20} /> {this.state.section.lesson.title}</span>;

        return <CicSiteLayout no_menu={true} title={title}>{main}</CicSiteLayout>;
    }
}