import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter,Link } from 'react-router-dom';
import CicStudentLayout from '../component/CicStudentLayout';
import { Tab, Tabs, Icon, Button, AnchorButton } from "@blueprintjs/core";
import { toast , isApiOk , showApiError } from '../util/Function';
import CiCVpsTable from '../component/CiCVpsTable'; 


@withRouter
@inject("store")
@observer
export default class CiCVpsManage extends Component
{
    state = {"vps_list":[],"selected_tab":"running","minutes":false};

    async componentDidMount()
    {
        this.loadVps();
        this.loadCloudtime();
    }

    async loadVps()
    {
        const { data } = await this.props.store.vps_my_list();
        //const data = null;
        if( isApiOk( data ) )
        {
            // console.log( data.data );
            this.setState( {"vps_list":data.data} );
        }
        else
            showApiError( data );
    }

    async loadCloudtime()
    {
        const { data } = await this.props.store.vps_my_time();
        //const data = null;
        if( isApiOk( data ) )
        {
            // console.log( data.data );
            this.setState( {"minutes":data.data} );
        }
        else
            showApiError( data );
    }

    selected( e )
    {
        this.setState({"selected_tab":e});
    }  

    onUpdate()
    {
        toast("命令已经提交，云端执行需要几分钟时间，请耐心等待");
        
        // 每五秒检查一次
        this.ck = window.setInterval( ()=>this.loadVps() , 1000*10 );
        // 检查五分钟，然后不检查了
        window.setTimeout( ()=>window.clearInterval(this.ck), 1000*60*5 );
    }

    componentWillUnmount()
    {
        window.clearInterval(this.ck);
    }
    
    render()
    {
        const main = <div className="padbox">
            <Tabs id="vpstable"  selectedTabId={this.state.selected_tab} onChange={(e)=>this.selected(e)}>
        <Tab id="running" title="正在运行" panel={<CiCVpsTable data={this.state.vps_list} onUpdate={()=>this.onUpdate()} />} />
        </Tabs>
            </div>;
        const side = this.state.minutes ? <div className="padbox">
            
            <div><AnchorButton href="https://learn.ftqq.com/22" target="_blank" icon="time" text="购买时间" large={true} minimal={true} /></div>
            <div className="explain">可用时间：{this.state.minutes} 分钟</div>

            
        </div>:null;
        const title = <div className="cic-bread-nav">云沙箱环境管理</div>;

        return <CicStudentLayout main={main} side={side} title={title} />;
    }
}