import React, { Component, Fragment } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter,Link } from 'react-router-dom';
import CicStudentLayout from '../component/CicStudentLayout';
import { Icon, Button, ControlGroup, InputGroup } from "@blueprintjs/core"; 
import { toast , isApiOk , showApiError } from '../util/Function';

@withRouter
@inject("store")
@observer
export default class CicMyLessonList extends Component
{
    state = {"lesson_list":[],"excode":"","order_id":"","necode":""};
    
    async componentDidMount()
    {
        this.loadLesson();
        this.renewOrderList();
    }

    async renewOrderList()
    {
        await this.props.store.lesson_order_refresh();
    }

    async loadLesson()
    {
        const { data } = await this.props.store.mylesson_list();
        //const data = null;
        if( isApiOk( data ) )
        {
            // console.log( data.data );
            this.setState( {"lesson_list":data.data} );
        }
        else
            showApiError( data );
    }

    async exchage()
    {
        const { data } = await this.props.store.lesson_exchange( 0 , this.state.excode );    
            
        if( isApiOk( data ) )
        {
            // 课程兑换成功，刷新本页数据
            toast("领取成功");
            this.setState({"excode":""});
            await this.loadLesson();
        }
        else
            showApiError( data );
    }

    async ne_exchage()
    {
        
        const { data } = await this.props.store.lesson_netease_exchange( this.state.order_id , this.state.necode );    
            
        if( isApiOk( data ) )
        {
            // 课程兑换成功，刷新本页数据
            toast("领取成功");
            this.setState({"necode":""});
            await this.loadLesson();
        }
        else
            showApiError( data );
    }

    changed( e , name )
    {
        // console.log( e.target );
        let o = {};
        o[name] = e.target.value;
        this.setState( o );
    }
    
    render()
    {
        const main = <div className="padbox cic-mylesson">
            { this.state.lesson_list && this.state.lesson_list.map( item => <div key={item.id} className="cic-mylesson-line">

            <div className="left">
                <div className="mytitle"><Link to={"/student/lesson/index/"+item.id}>{item.title}</Link></div>
                <div className="explain">{item.subtitle}</div>
            </div>
            <div className="right">
            <Button className="line-button" icon="numbered-list" text="查看目录" large={true} minimal={true} onClick={()=>this.props.history.push("/student/lesson/index/"+item.id)}/>
            </div>    
            </div> ) }
        </div>;
        const side = <Fragment><div className="padbox">
            
        <div className="cic-lesson-exbox">领取课程</div>

        <ControlGroup fill={true} vertical={true}>
            <InputGroup placeholder="输入领课码" value={this.state.excode} onChange={(e)=>this.changed( e , 'excode' )} large={true}/>
            <Button onClick={()=>this.exchage()} large={true} >领取</Button>
        </ControlGroup>
        
        </div>
        <div className="padbox">
            
        <div className="cic-lesson-exbox">网易云课堂领课</div>

        <ControlGroup fill={true} vertical={true}>
            <InputGroup placeholder="输入领课码" value={this.state.necode} onChange={(e)=>this.changed( e , 'necode' )} large={true}/>
            <InputGroup placeholder="输入订单号" value={this.state.order_id} onChange={(e)=>this.changed( e , 'order_id' )} large={true}/>
            <Button onClick={()=>this.ne_exchage()} large={true} >领取</Button>
        </ControlGroup>
        
        </div></Fragment>
        ;
        const title = <span>已购买和领取的课程</span>;

        return <CicStudentLayout main={main} side={side} title={title} />;
    }
}