import React, { Component,Fragment } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter, Link } from 'react-router-dom';
import { HTMLTable, Checkbox, Icon, Menu, MenuItem, MenuDivider, Popover, Position, Tooltip, Button, Overlay  } from "@blueprintjs/core";
import { isApiOk , showApiError, setStringGlobal, handleStringGlobal,handleIsGlobal, toast, intval } from '../util/Function';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import fileDownload from 'js-file-download';
import moment from 'moment';
import 'moment/locale/zh-cn.js';
moment.locale('zh-cn');

@withRouter
@inject("store")
@observer
export default class CicCodeTable extends Component
{
    state = {"bat_info":false,"markit":true,"bat_id":0};
    
    async setSent( id , status )
    {
        const { data } = await this.props.store.code_set_sent( id , status );
        //const data = null;
        if( isApiOk( data ) )
        {
            if( this.props.onUpdated ) this.props.onUpdated();
        }
        else
            showApiError( data );
    }

    async delete( id )
    {
        if( window.confirm( "禁用以后不能恢复，只能重新创建。继续？" ) )
        {
            const { data } = await this.props.store.code_set_disabled( id );
        
            if( isApiOk( data ) )
            {
                if( this.props.onUpdated ) this.props.onUpdated();
            }
            else
                showApiError( data );
        }
    }

    async batCheckDownload( id )
    {
        const { data } = await this.props.store.code_get_bat_info( id );
        
        if( isApiOk( data ) )
        {
            this.setState( {"bat_info":data.data , "bat_id": id } );
        }
        else
            showApiError( data );
    }

    async batDoDownload()
    {
        const { data } = await this.props.store.code_download_bat( this.state.bat_id , this.state.markit ? 1: 0  );
        
        if( isApiOk( data ) )
        {
            if( data.data.text )
            {
                // force download
                fileDownload( data.data.text , `[cic]领课码.${this.state.bat_id}.txt` );

                this.batCancle();
                if( this.props.onUpdated ) this.props.onUpdated();

            }
            //this.setState( {"bat_info":data.data , "bat_id": id } );
        }
        else
            showApiError( data );
    }

    batCancle()
    {
        this.setState({ "bat_info":false, "bat_id":0 });
    }
    
    
    render()
    {
        const code_list = this.props.data;
        if( !code_list ) return null;

        return <Fragment><HTMLTable interactive={true} className="cic-table">
            <thead>
            <tr>
                <td className="cic-checkbox-col"><Checkbox className="cic-clean"/></td>
                <td>领课码</td>
                <td>批次</td>
                <td className="center">可用次数</td>
                <td className="center">过期时间</td>
                <td className="center">创建时间</td>
                <td></td>
            </tr>
            </thead>

            <tbody>

            { code_list && code_list.map( item =>
                {
                    const classname = parseInt(item.sent) == 1 ? 'unpublished' : 'published';

                    const  classname2 = parseInt(item.used_times) < parseInt(item.can_use_times) ? ' ' : 'used';
                    
                    return <tr key={item.id}>
                        <td><Checkbox className="cic-clean"/></td>
                        <td>
                        <Tooltip content={item.notes} position={Position.TOP}>
                        <span className={ classname + " " + classname2 + " right-5"}>{item.code.substring(0,18)}...</span></Tooltip>
                        
                        <CopyToClipboard text={item.code} onCopy={()=>
                            {
                                toast("领课码已复制到剪贴板,并标记为发送");
                                this.setSent( item.id , 1 );
                            }}><Button small={true} minimal={true} icon="clipboard" className="cic-icon" /></CopyToClipboard> </td>
                        <td>{item.batch_id ? item.batch_id : "-" }</td>    
                        <td className="center">{item.used_times}/{item.can_use_times}</td>
                        
                        <td className="center">{moment(item.expire_date).format("YYYY-MM-DD")}</td>
                        <td className="center">{moment(item.created_at).fromNow() }</td>
                        <td className="actions">
                            <Popover content={<Menu>
                
                            { parseInt(item.sent) == 1 ? <MenuItem icon="edit" text="标记为未发送" onClick={()=>this.setSent( item.id , 0 )} /> : <MenuItem icon="edit" text="标记为已发送" onClick={()=>this.setSent( item.id , 1 )} /> }

                                
                               
                                { parseInt(item.disabled) !== 1 && 
                                <Fragment>
                                {item.batch_id &&
                                    <MenuItem icon="download" text="导出同批次全部可用领课码" onClick={()=>this.batCheckDownload(item.batch_id)}/> 
                                }
                                    
                                <MenuDivider />
                                <MenuItem icon="cross" text="禁用" onClick={()=>this.delete(item.id)}/>
                                </Fragment>
                            }
                                

                            </Menu>} position={Position.BOTTOM}>
                                <Button small={true} minimal={true} icon="more" className="cic-icon" />
                            </Popover>
                        </td>
                    </tr>
                }) 
            }

            
            
            </tbody>

            {/* <tfoot>
            <tr>
                <td className="cic-checkbox-col"><Icon icon="arrow-right" className="cic-icon" /></td>
                <td colSpan="6">归档&nbsp;&nbsp;&nbsp;恢复</td>
               
            </tr>
            </tfoot> */}


        </HTMLTable>
        <Overlay isOpen={this.state.bat_info} className="cic-overlay">
            <div className="overbox">
                <div className="centerbox">
                    <h3>共有{this.state.bat_info}枚领课码可以下载</h3>
                    <Checkbox checked={this.state.markit} label="同时标记为已发送" onChange={(e)=>this.setState({"markit":e.target.checked})} />
                    <div className="std-hr"/>
                    <div className="cic-form-actionbar">
                    <Button large={true} onClick={(e)=>this.batCancle()} minimal={true}>取消</Button>
                    <Button large={true} onClick={(e)=>this.batDoDownload()}>点此下载Txt文件</Button>
                    </div>
                    
                </div>
            </div>
        </Overlay>
        </Fragment>;
    }
}