import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter,Link } from 'react-router-dom';
import CicStudentLayout from '../component/CicStudentLayout';
import { Icon } from "@blueprintjs/core"; 
import { isApiOk , showApiError, intval, toast} from '../util/Function' ;
import CicIndexBoxReadOnly from '../component/CicIndexBoxReadOnly'; 


@withRouter
@inject("store")
@observer
export default class CicMyLessonIndex extends Component
{
    state = {"lesson":false,"index":[],"notice":false};

    async componentDidMount()
    {
        await this.loadData();
    }

    async loadData()
    {
        const id = intval(this.props.match.params.id);
        const { data } = await this.props.store.lesson_mydetail( id );    
        
        if( isApiOk( data ) )
        {
            this.setState({"lesson":data.data});
            await this.loadInex( data.data.id );
        }
        else
            showApiError( data ); 
        
    }

    async loadInex( id )
    {
        const {data} = await this.props.store.myindex_load( id );
        if( isApiOk( data ) )
        {
            if( data.data.length < 1 )
                this.setState( {"notice":"暂无目录"} );
            else
                this.setState( {"index":data.data} );
        }
        else
            showApiError( data );
    }
    
    render()
    {
        if( !this.state.lesson  ) return null;
        
        const main = <div className="padbox cic-myindex-page">
        { this.state.notice ? <span>{this.state.notice}</span> : <div className="padbox center-v std-index whiteindex">
        <CicIndexBoxReadOnly 
        data={this.state.index} 
        lesson_id={this.state.lesson.id}
        /></div> }    
        </div>;

        const side = null;
        const title = <div className="cic-bread-nav"><Link to="/student/lesson">已购买和领取的课程</Link > <Icon icon="double-chevron-right" className="cic-icon" /> {this.state.lesson.title} <Icon icon="double-chevron-right" className="cic-icon" /> 目录 </div>;

        return <CicStudentLayout main={main} side={side} title={title} />;
    }
}