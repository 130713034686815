import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter, Link } from 'react-router-dom';
import { HTMLTable, Checkbox, Icon, Menu, MenuItem, MenuDivider, Popover, Position, Button, AnchorButton  } from "@blueprintjs/core";
import { isApiOk , showApiError, setStringGlobal, handleStringGlobal,handleIsGlobal, toast, intval } from '../util/Function';


@withRouter
@inject("store")
@observer
export default class CicSandboxTable extends Component
{
    async delete( id )
    {
        // lesson_remove
        if( id > 0 )
        {
            const { data } = await this.props.store.sandbox_remove( id );
            if( isApiOk( data ) )
            {
                if( this.props.onDelete ) this.props.onDelete();
            }
            else
                showApiError( data ); 
        }
    }

    async undelete( id )
    {
        // sandbox_remove
        if( id > 0 )
        {
            const { data } = await this.props.store.sandbox_unremove( id );
            if( isApiOk( data ) )
            {
                if( this.props.onUndelete ) this.props.onUndelete();
            }
            else
                showApiError( data ); 
        }
    }
    
    
    render()
    {
        const sandbox_list = this.props.data;
        if( !sandbox_list ) return null;

        return <HTMLTable interactive={true} className="cic-table">
            <thead>
            <tr>
                <td className="cic-checkbox-col" style={{"width":"50px"}}><Checkbox className="cic-clean"/></td>
                <td className="center" style={{"width":"50px"}}>类型</td>
                <td>名称</td>
                <td className="center" style={{"width":"100px"}}>嵌入</td>
                <td className="center" style={{"width":"100px"}}>使用</td>
                <td className="center" style={{"width":"100px"}}>通过</td>
                <td style={{"width":"80px"}}></td>
            </tr>
            </thead>

            <tbody>

            { sandbox_list && sandbox_list.map( item =>
                {
                    return <tr key={item.id}>
                        <td><Checkbox className="cic-clean"/></td>
                        <td className="center">{ parseInt(item.type) == 1 ? <Button small={true} minimal={true} icon="globe-network" className="cic-icon" /> : <Button small={true} minimal={true} icon="cloud" className="cic-icon" />}</td>
                        <td>
                        
                        {parseInt(item.published) == 1 ? <span className="published right-5">{item.title}</span> : <span className="unpublished right-5">{item.title}</span>} 
                        
                        <AnchorButton target="_blank" href={"/sandbox/init/"+item.id} small={true} minimal={true} icon="document-open" className="cic-icon" /> 

                        </td>
                        <td className="center">- <Button small={true} minimal={true} icon="caret-down" className="cic-icon" /></td>
                        <td className="center">- <Button small={true} minimal={true} icon="caret-down" className="cic-icon" /></td>
                        <td className="center">- <Button small={true} minimal={true} icon="caret-down" className="cic-icon" /></td>
                        
                        <td className="actions">
                            <Popover content={<Menu>
                
                                <MenuItem icon="edit" text="修改" onClick={()=>this.props.history.push("/admin/sandbox/modify/"+item.id)} /> 
                                <MenuDivider />
                                { parseInt(item.deleted) == 1 ? <MenuItem icon="cross" text="恢复" onClick={()=>this.undelete(item.id)}/>: <MenuItem icon="cross" text="归档" onClick={()=>this.delete(item.id)}/>}
                                

                            </Menu>} position={Position.BOTTOM}>
                                <Button icon="more" className="cic-icon" small={true} minimal={true}/>
                            </Popover>
                        </td>
                    </tr>
                }) 
            }

            
            
            </tbody>

            {/* <tfoot>
            <tr>
                <td className="cic-checkbox-col"><Icon icon="arrow-right" className="cic-icon" /></td>
                <td colSpan="6">归档&nbsp;&nbsp;&nbsp;恢复</td>
               
            </tr>
            </tfoot> */}


        </HTMLTable>;
    }
}